import { Image } from "antd"
import React, { Component } from 'react'
import { Link } from "react-router-dom" 
import { Swiper, SwiperSlide} from 'swiper/react/swiper-react';

import { startConnection } from "../../api/httpEngine/HttpEngine"
import CommonDataHttpItem from "../../api/httpItem/CommonDataHttpItem"
import LoginHttpItem from "../../api/httpItem/LoginHttpItem"
import '../../css/Login.css'
import { resetManagerUserEntity } from "../../data/ManagerUserProfileEntity"
import { showTips } from "../../utils/utils"
import Empty from '../../view/Empty'
import InputTextField from "../../view/InputTextField"
import Loading from "../../view/Loading"
import OneButton from "../../view/OneButton"
import OneCenterTitle from '../../view/OneCenterTitle'
import PasswordInputTextField from "../../view/PasswordInputTextField"
import 'swiper/swiper-bundle.css'
import SwiperCore, { Autoplay , EffectFade} from 'swiper';

SwiperCore.use([Autoplay]);
SwiperCore.use([EffectFade]); 

class Login extends Component {
    
    state = {
        userName : '',
        password : '',
        appIcon : '',
        imgs : [],
        isLoading : false,
    }

    componentDidMount = () => {
        this.setState ({
            isLoading : true,
        })
        let commonDataHttpItem = new CommonDataHttpItem()
        startConnection (commonDataHttpItem).then (response => {
            let data = response.result.data
            this.setState ({
                isLoading : false,
                appIcon : data.appIcon,
                imgs : data.imgs,
            })
        }, error => {
            this.setState ({
                isLoading : false,
            })
        })
    }

    onUserDidChangedCallback = (inputTextFieldText) => {
        this.setState({userName : inputTextFieldText})
    }

    onPasswordDidChangedCallback = (inputTextFieldText) => {
        this.setState({password : inputTextFieldText})
    }

    clickFinishedButtonCallback = (oneButtonProps) => {
        this.setState({isLoading : true})

        let userName = this.state.userName
        let password = this.state.password

        let error = ''
        if (userName.length === 0)
        {
            error = '请输入用户名'
        }
        else if (password.length === 0)
        {
            error = '请输入密码'
        }

        if (error.length > 0)
        {
            showTips (error, 2)
            this.setState({isLoading : false })
            return
        }
        
        let md5 = require ('md5')
        let tmpPassword = md5(password).toUpperCase()
        let loginItem = new LoginHttpItem(userName , tmpPassword)
        startConnection(loginItem).then( (response) => {
            this.setState({isLoading : false })
            showTips (response.data.msg, 0) 
            resetManagerUserEntity (response['data']['data'])
            this.props.history.push ('/main')
        }, error => {
            this.setState({isLoading : false })
        })
    }
    
    images = () => {
        let jsxarray = []
        if (this.state.imgs.length > 0)
        {
            for (let i = 0; i < this.state.imgs.length ; i++)
            {
                let data = this.state.imgs[i]
                jsxarray.push (
                    <SwiperSlide key = {i}><Image src = {data} width='100%' height='100%' className="Login_Banner" preview = {false}></Image></SwiperSlide> ,    
                )
            }
        }   
        return jsxarray
    }

    render(){
        return (
            <Link to = '/login'>
                <div className="Login_bgView"> 
                    <div className="Login_loginView">
                        <div className = "Login_LeftView"> 
                            <div className= "Login_OptionView">
                                <Image
                                    className = "Login_Avatar"
                                    src = {this.state.appIcon}
                                    preview = {false}
                                />
                                <Empty height = '20px'/>
                                <OneCenterTitle title = '玲玲音乐盒' height = '50px' font = '25px' textColor = 'rgb(51, 51, 51)'/>
                                <Empty height = '30px'/>
                                <InputTextField
                                    height = '50px'
                                    text = {this.state.userName} 
                                    backgroundColor = 'clear' 
                                    placeholder = '请输入用户名' 
                                    textColor = "rgb(51, 51, 51)"
                                    onChangedCallBack = {this.onUserDidChangedCallback}
                                /> 
                                <Empty height = '20px'/>
                                <PasswordInputTextField height = '50px' 
                                    text = {this.state.password} 
                                    backgroundColor = 'clear' 
                                    placeholder = '请输入密码' 
                                    textColor = 'rgba(51, 51, 51 , 1)'
                                    onChangedCallBack = {this.onPasswordDidChangedCallback}
                                />
                                <Empty height = '40px'/>
                                <OneButton buttonTitle='登录' callback = {this.clickFinishedButtonCallback}/>
                            </div>
                        </div>
                        <div className = "Login_RightView">
                            <Swiper effect = {'fade'} loop = {true} allowTouchMove = {false} className = "Login_Swiper"   autoplay={{
                                "delay": 2500,
                                "disableOnInteraction": false,    
                            }}>
                                {this.images()}
                            </Swiper>
                        </div>
                    </div>
                    <Loading showLoading = {this.state.isLoading}/>
                    <div className = 'Login_BottomView'>
                        <a className="LoginCaseNumber" href="#" onClick={() => window.open('https://beian.miit.gov.cn/#/Integrated/index')}>
                            备案号：湘ICP备2021020043号-1
                        </a>
                    </div>
                </div>
            </Link>
        )
    }
}
  
export default Login