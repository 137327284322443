import { Image } from 'antd'
import '../css/MusicItem.css'
import OneIcon from './OneIcon'
import { useState } from 'react'

const MusicItem = (props) => {
   
    const [isHover, setIsHover] = useState(false)

    const clickView = () => {
        if (props.clickView)
        {
            props.clickView (props)
        }
    }

    const clickFavorIcon = () => {
        if (props.clickFavorIcon)
        {
            props.clickFavorIcon (props)
        }
    }

    const clickMVIcon = () => {
        if (props.clickMVIcon)
        {
            props.clickMVIcon (props)
        }
    }

    const clickSinger = (event) => {
        if (props.canClickSinger)
        {
            event.stopPropagation()
            if (props.clickSinger)
            {
                props.clickSinger (props)
            }
        }
    }

    const showHighColor = (event) => {
        setIsHover (true)
    }

    const showNormalColor = (event) => {
        setIsHover (false)
    }
    
    return(
        <div className = {props.isChoosed ? 'MusicItem_ContentViewSelected': 'MusicItem_ContentView'} onClick = {clickView}>
            <div style = {{width : '20px'}}></div>
            <div className = 'MusicItem_OptionView' key = 'MusicItem_OptionView'>
                <div className = 'MusicItem_Option' style = {{width : '30%'}} key = {1}>
                    <h1 className = 'MusicItem_Title' key = 'MusicItem_Title1'>{props.firstTitle}</h1>
                </div>
                <div className = 'MusicItem_Option' style = {{width : '30%'}} onClick = {clickSinger} key = {2}>
                    <h1 className = 'MusicItem_Title' key = 'MusicItem_Title2' style = {{color : (isHover && props.canClickSinger === true) ? 'rgb(239, 26, 14)': 'rgb(144, 145, 149)'}} onMouseEnter = {showHighColor} onMouseLeave = {showNormalColor}>{props.secondTitle}</h1>
                </div>
                <div className = 'MusicItem_Option' style = {{width : '10%'}} key = {0}>
                    <h1 className = 'MusicItem_Title' key = 'MusicItem_Title3'>{props.thirdTitle}</h1>
                </div>
                <div className = 'MusicItem_Option' style = {{width : '10%'}} key = {3}>
                    <Image
                        className = "MusicItem_Cover"
                        src = {props.coverImageUrl}
                        preview = {false}
                    />
                </div>
                <div className = 'MusicItem_Option' style = {{width : '10%'}} key = {4}>
                    <OneIcon
                        width = '30px'
                        height = '30px'
                        fontSize = '30px'
                        textColor = 'rgb(51, 51, 51)'
                        iconName = {props.mvIcon}
                        clickView = {clickMVIcon}
                    />
                </div>
                <div className = 'MusicItem_Option' style = {{width : '10%'}} key = {5}>
                    <OneIcon
                        width = '30px'
                        height = '30px'
                        fontSize = '30px'
                        textColor = 'rgb(51, 51, 51)'
                        iconName = {props.icon}
                        clickView = {clickFavorIcon}
                    />
                </div> 
            </div>
            <div className = 'MusicItem_BottomLine' key = 'MusicItem_BottomLine'>

            </div>
        </div>
    )
}

export default MusicItem