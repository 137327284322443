import '../css/MoreHorTagsView.css'
import TagView from './TagView'
import WidthEmpty from './WidthEmpty'
 
const MoreHorTagsView = (props) => {
 
    const clickView = (data) => {
        if (props.clickView)
        {
            props.clickView (data)
        }
    }

    const tags = () => {
        let jsxArray = []
        if (props.options.length > 0)
        { 
            for (let i = 0 ; i < props.options.length; i++)
            {
                let option = props.options[i]
                jsxArray.push (
                    <TagView
                        isChoosed = {option.isChoosed}
                        optionTitle = {option.optionTitle}
                        key = {'TagView' + i}
                        data = {option}
                        clickView = {clickView}
                    />,
                    <WidthEmpty
                        width = '5px'
                        height = '1px'
                        key = {'WidthEmpty' + i}
                    /> 
                )
            }
        }
        return jsxArray
    }

    return(
        <div className = 'MoreHorTagsView_ContentView'>
            {tags()}
        </div>
    )
}

export default MoreHorTagsView