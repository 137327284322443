import BaseHttpItem from "./BaseHttpItem"

export default class FavorMusicHttpItem extends BaseHttpItem{
    constructor (musicModel){ 
        super()
        this.descriptionItem = '收藏音乐'
        this.httpRequestUrl = 'myMusic/musicCollection'
        this.httpRequestMethod = 'post' 
        this.httpRequestPostParams = musicModel
    }
}