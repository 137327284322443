import '../css/OneButton.css'
import { Button } from 'antd';

const OneButton = (props) => {
 
    const cb = () => {
        if (props.callback)
        {
            props.callback (props)
        }
    }

    let buttonStyle = {backgroundColor : props.buttonBackgroundColor , color : props.buttonTextColor}

    return(
        <div className = 'OneButton_BgView'>
            <div className='OneButton_ContentView'>
                <Button className = 'OneButton_button' 
                type = "default" 
                shape = {'round'}
                onClick = { cb }    
                style = {buttonStyle}
                >{props.buttonTitle}</Button>
            </div>
        </div>
    )
}

export default OneButton