import ReactPlayer from "react-player"; 
import '../../css/VideoPlayer.css'
import { useState , useEffect } from 'react'
import { isNull } from "../../utils/utils";
import OneIcon from "../../view/OneIcon";

const VideoPlayer = (props) => {

    const [play, setPlay] = useState(true)

    let innerStyle = {display: props.isShow === false ? "none" : ''}

    useEffect ( () => {
        setPlay (!isNull(props.VideoPlayer))
    },[props.VideoPlayer])

    const clickCloseView = () => {
        if (props.clickCloseView)
        {
            setPlay (false)
            props.clickCloseView()
        }
    }

    return(
        <div style = {innerStyle} className = 'VideoPlayer_BgView'>
            <div className = "VideoPlayer_CloseIcon" onClick = {clickCloseView}>
                <OneIcon
                    width = '50px'
                    height = '50px'
                    fontSize = '50px'
                    textColor = 'rgb(216, 30, 6)'
                    iconName = 'icon-round_close_light'
                    clickView = {clickCloseView}
                />
            </div>
            <ReactPlayer
                loop = {false}
                url = {props.VideoPlayer}  
                width = '80%'
                height = '80%'
                playing = {play}
                controls = {true}
            />
        </div>
    )
}
 
export default VideoPlayer