import '../css/SearchBar.css'

import { createFromIconfontCN } from '@ant-design/icons';
import { kIconFontUrl } from '../global/data';
import { Input } from 'antd';


const SearchBar = (props) => {

    const IconFont = createFromIconfontCN({
        scriptUrl: [
          kIconFontUrl
        ],
    });

    const onChange = (e) => {
        if (props.textDidChanged)
        {
            props.textDidChanged (e.target.name, e.target.value)
        }
    }

    const onClick = () => { 
        if (props.didBeginEdit)
        {
            props.didBeginEdit ()
        }
    }

    return(
        <div className='SearchBar_BgView'>
            <div className = {props.isBorderRadius ? 'SearchBar_ContentView' : 'SearchBar_ContentViewNoRadius'}>
                <IconFont className = 'SearchBar_Icon' type='icon-sousuo'/>
                <Input className = 'SearchBar_TextField' 
                    onClick = {onClick}
                    bordered = {false}
                    name = {props.modifyKey}
                    placeholder = {props.placeHolder} 
                    allowClear 
                    onChange = {onChange} 
                    value = {props.text}
                    disabled = {!props.canEdit}
                />
            </div>
        </div>
    )
}

export default SearchBar