import '../../css/MusicList.css'
import React, { useState , useEffect } from 'react';
import Empty from '../../view/Empty'
import SearchBar from '../../view/SearchBar';
import TitleSearchedHttpItem from '../../api/httpItem/TitleSearchedHttpItem';
import { startConnection } from '../../api/httpEngine/HttpEngine';
import OneCenterTitle from '../../view/OneCenterTitle';
import MusicSearchHttpItem from '../../api/httpItem/MusicSearchHttpItem';
import ListViewHeadView from '../../view/ListViewHeadView';
import { isNull, showTimeFormatter, showTips } from '../../utils/utils';
import MusicItem from '../../view/MusicItem';
import MvUrlHttpItem from '../../api/httpItem/MvUrlHttpItem';
import { connect } from 'react-redux'
import { kIsPause, kIsPlaying, kMusicDataSourceChanged, kSongidChanged, kStatusHasChanged } from '../../redux/reducer/playReducer'
import CancelFavorMusicHttpItem from '../../api/httpItem/CancelFavorMusicHttpItem';
import FavorMusicHttpItem from '../../api/httpItem/FavorMusicHttpItem';
import VideoPlayer from '../common/VideoPlayer';
import LeftTitleRightPageIndex from '../../view/LeftTitleRightPageIndex';
import SingerDetail from './SingerDetail';

const MusicList = (props) => {

    const [searchContent, setSearchContent] = useState('')
    const [searchResponse, setSearchResponse] = useState([])
    const [isSearchState, setIsSearchState] = useState('')
    const [page, setPage] = useState(1)
    const [dataSource, setDataSource] = useState([])
    const [videoPlayerUrl, setVideoPlayerUrl] = useState('')
    const [isShowVideoPlayView, setIsShowVideoPlayView] = useState(false)
    const [isShowSingerDetail, setIsShowSingerDetail] = useState(false)
    const [singerId, setSingerId] = useState('')

    useEffect(() => {
        //当搜索数据有变化时
        if (searchContent.length > 0)
        {
            if (isSearchState)
            {
                //如果是快速搜索
                let titleSearchedHttpItem = new TitleSearchedHttpItem(searchContent)    
                startConnection (titleSearchedHttpItem).then (response => {
                    let dataArray = []
                    for (let i = 0 ; i < response.result.data.length ; i++)
                    {
                        let data = response.result.data[i]
                        for (let j = 0 ; j < data.RecordDatas.length; j++)
                        {
                            let dataInfo = data.RecordDatas[j]
                            dataArray.push (dataInfo.HintInfo)
                        }
                    }
                    setSearchResponse (dataArray)
                }, error => {
                    console.log ('error = ' ,error)
                })
            }
            else
            {
                //如果是搜索音乐
                let musicSearchHttpItem = new MusicSearchHttpItem (searchContent, page)
                startConnection (musicSearchHttpItem).then (response => {
                    setDataSource(response.result.data)
                }, error => {
                    console.log ('error = ' ,error)
                })
            }
        }
    }, [searchContent, page])
     
    //搜索数据
    const textDidChanged = (key , value) => {
        setSearchContent (value)
    }

    //开始搜索
    const didBeginEdit = () => {
        setIsSearchState(true)
    }

    //点击搜索数据结果
    const clickSearchListView = (data) => {
        setIsSearchState (false)
        setSearchContent (data)
    } 

    //搜索结果UI
    const searchListView = () => {
        if (isSearchState && searchResponse.length > 0)
        {
            let searchListJsxArray = []
            for (let i = 0 ; i < searchResponse.length; i++)
            {
                let searchData = searchResponse[i]
                searchListJsxArray.push (
                    <div className = 'searchListHighItemView' key={searchData + i} dangerouslySetInnerHTML={{__html : highText(searchData, searchContent)}} data = {searchData} onClick = { () => { clickSearchListView(searchData) }}></div>,
                    <Empty 
                        key = {'listEmpty' + i}
                        height = '4px'
                    />,
                )
            }

            let jsxArray = []
            jsxArray.push (
                <div style = {{width : '100%' , height : '400px', backgroundColor : 'rgb(255, 255, 255, 0.7)', borderRadius : '0px 0px 22px 22px'}} key = 'bgView'>
                    <Empty 
                        key = 'topSpace'
                        height = '10px'
                    />
                    <div style = {{marginLeft : '14px'}} key = 'searchListOptionNoticeTitle'>
                        <OneCenterTitle
                            height = '20px'
                            font = '14px'
                            textColor = 'rgb(117, 117, 117)'
                            title = '您可能想搜索'
                            textAlign = 'left'
                            key = 'OneCenterTitle'
                        />
                    </div>
                    <Empty 
                        key = 'bottomSpace'
                        height = '10px'
                    />
                    <div className = 'searchListView' key = 'searchListView'>
                        {searchListJsxArray}
                    </div>
                    <Empty 
                        key = 'listBottomSpace'
                        height = '10px'
                    />
                </div>
            )
            
            return jsxArray
        }
    }

    //点击播放歌曲
    const clickView = (data) => {
        if (props.player.songid === data.data.songid)
        {
            if (props.player.isPlaying)
            {
                props.pause ()
            }
            else
            {
                props.play ()
            }
            return
        }
        props.changedPlayListDataSources(dataSource)
        props.changedSongid(data.data.songid)
    }

    //播放MV
    const clickMVIcon = (data) =>{
        let mvUrlHttpItem = new MvUrlHttpItem(data.data.songid)
        startConnection (mvUrlHttpItem).then (response => {
            setVideoPlayerUrl (response.result.data)
            setIsShowVideoPlayView (true)
            props.pause ()
        },error => {

        })
    } 

    //点（取消）收藏
    const clickFavorIcon = (data) => {
        if (parseInt(data.data.status) === 1)
        {
            //取消收藏
            let cancelFavorMusicHttpItem = new CancelFavorMusicHttpItem (data.data.songid)
            startConnection (cancelFavorMusicHttpItem).then (response => {
                showTips ('取消收藏成功',0)
                data.data.status = 0
                if (data.data.songid === props.player.songid)
                {
                    //如果当前改变的是正在播放的歌曲
                    props.statusChanged(0)
                }
                for (let i = 0 ; i < dataSource.length; i++)
                {
                    let musicData = dataSource[i]
                    if (musicData.songid === data.data.songid)
                    {
                        musicData.status = '0'
                        break
                    }
                }
                let tmpDataSources = JSON.parse(JSON.stringify(dataSource))
                setDataSource(tmpDataSources)
            } , error => {

            })
        }
        else
        {
            //收藏
            let favorMusicHttpItem = new FavorMusicHttpItem (data.data)
            startConnection (favorMusicHttpItem).then (response => {
                showTips ('收藏歌曲成功',0)
                data.data.status = 1
                if (data.data.songid === props.player.songid)
                {
                    //如果当前改变的是正在播放的歌曲
                    props.statusChanged(1)
                }
                for (let i = 0 ; i < dataSource.length; i++)
                {
                    let musicData = dataSource[i]
                    if (musicData.songid === data.data.songid)
                    {
                        musicData.status = '1'
                        break
                    }
                }
                let tmpDataSources = JSON.parse(JSON.stringify(dataSource))
                setDataSource(tmpDataSources)
            } , error => {

            })
        }
    }

    //关闭MV
    const clickVideoPlayer = () => {
        setVideoPlayerUrl ('')
        setIsShowVideoPlayView (false)
        if (!isNull(props.player.songid) && props.player.songid.length > 0)
        {
            props.play()
        }
    }

    //点击歌手
    const clickSinger = (data) => {
        setSingerId (data.data.singerId)
        setIsShowSingerDetail (true)
    }

    //音乐数据UI
    const musicListView = () => {
        let jsxArray = []
        if (dataSource.length > 0)
        {
            let musicListArray = []
            for (let i = 0 ; i < dataSource.length ; i++)
            {
                let musicData = dataSource[i]
                let durationStr = showTimeFormatter (musicData.duration)
                musicListArray.push (
                    <MusicItem
                        firstTitle = {musicData.title}
                        secondTitle = {musicData.author}
                        thirdTitle = {durationStr}
                        coverImageUrl = {musicData.pic}
                        icon = {parseInt(musicData.status) === 1 ? 'icon-w_aixin' : 'icon-xihuan'}
                        mvIcon = 'icon-yingshi'
                        key = {musicData.songid + i}
                        data = {musicData}
                        clickView = {clickView}
                        clickFavorIcon = {clickFavorIcon}
                        clickMVIcon = {clickMVIcon}
                        clickSinger = {clickSinger}
                        isChoosed = {musicData.songid === props.player.songid}
                        canClickSinger = {true}
                    />
                )
            }
            jsxArray.push (
                <Empty height = '10px' key = 'MusicList_ContentViewEmptyTopTitleEmpty'/>,
                <LeftTitleRightPageIndex
                    height = '50px'
                    leftTitle = '搜索结果'
                    currentPage = {'第' + page + '页'}
                    key = 'LeftTitleRightPageIndex'
                    clickPreviewIcon = {clickPreviewPage}
                    clickNextIcon = {clickNextPage}
                />,
                <Empty height = '10px' key = 'MusicList_ContentViewEmptyBottomTitleEmpty'/>,
                <div className = 'musicListView' key = 'musicListView'>
                    <Empty height = '10px' key = 'MusicList_ContentViewEmptyTopSpaceEmpty'/>
                    <ListViewHeadView
                        firstTitle = '歌名'
                        secondTitle = '歌手'
                        thirdTitle = '时长'
                        fourthTitle = '封面'
                        fifthTitle = 'MV'
                        sixthTitle = '#'
                        key = 'ListViewHeadView'
                    />
                    {musicListArray}
                </div>,
            )  
        }
        return jsxArray
    }

    const highText = (str, keyWord) => {
        let result = str.replace(new RegExp (keyWord, 'gi'), (match) => {
            return `<span class = "searchListHighItem"}}>${match}</span>`
        })
        return result
    }

    //下一页
    const clickNextPage = () => {
        let tmpPage = page
        tmpPage++
        setPage (tmpPage)
    } 

    const clickPreviewPage = () => {
        let tmpPage = page
        if (tmpPage > 1)
        {
            tmpPage--
        }
        setPage (tmpPage)
    }

    //关闭歌手详情
    const clickCloseDetailView = () => {
        setIsShowSingerDetail (false)
    }

    return(
        <div className='MusicList_BgView' key = 'MusicList_BgView'>
            <div className='MusicList_ContentView' key = 'MusicList_ContentView'>
                <Empty height = '20px' key = 'MusicList_ContentViewEmpty'/>
                <SearchBar
                    isBorderRadius = {!isSearchState}
                    modifyKey = 'searchContent'
                    placeHolder = '搜索关键字'
                    text = {searchContent}
                    didBeginEdit = {didBeginEdit}
                    textDidChanged = {textDidChanged}
                    key = 'searchContent'
                    canEdit = {true}
                />
                {searchListView()}
                {musicListView()} 
                <VideoPlayer
                    closeIcon = 'icon-round_close_light'
                    VideoPlayer = {videoPlayerUrl}
                    isShow = {isShowVideoPlayView}
                    clickCloseView = {clickVideoPlayer}
                    key = 'VideoPlayer'
                />
            </div>
            <SingerDetail
                isShow = {isShowSingerDetail}
                singerId = {singerId}
                clickCloseView = {clickCloseDetailView}
            />
        </div>
    )
}

const mapStateToProps = (state) => {
    return state
}

const mapDispatchToProps = (dispatch) => {
    return {
        //暂停
        pause : () => {
            dispatch ({type : kIsPause})
        },

        //播放
        play : () => {
            dispatch ({type : kIsPlaying})
        },

        //切换歌曲列表
        changedPlayListDataSources : (list) => {
            dispatch ({type : kMusicDataSourceChanged, musicList : list})
        },
 
        //切换歌曲id
        changedSongid : (songid) => {
            dispatch ({type : kSongidChanged, songid : songid})
        },

        //歌曲收藏状态改变
        statusChanged : (status) => {
            dispatch ({type : kStatusHasChanged, status: status})
        },
    }
}

 
export default connect( mapStateToProps ,mapDispatchToProps )(MusicList)