import { Input } from 'antd';
import '../css/InputTextField.css'
import { createFromIconfontCN } from '@ant-design/icons';
import { kIconFontUrl } from '../global/data';

const InputTextField = (props) => {
    const onChanged = (e) => {
        if (props.onChangedCallBack)
        {
            props.onChangedCallBack(e.target.value)
        }
    }
    
    const IconFont = createFromIconfontCN({
        scriptUrl: [
          kIconFontUrl
        ],
    });

    let innerStyle = {height : props.height, width : '100%' , backgroundColor: props.backgroundColor}
    let textStyle = {color :  props.textColor}
    return(
        <div style={innerStyle}>
            <Input className='InputTextField_textField' style={textStyle}
                size = "large" 
                defaultValue = {props.text} 
                placeholder = {props.placeholder} 
                onChange = {onChanged}
                bordered = {true}
                type='text'
                value = {props.text}
                disabled = {false}
                prefix = {
                    <IconFont type='icon-zhanghu' className = 'InputTextField_icon'/>
                }
            />
        </div>
    )
}

export default InputTextField