import '../css/ThreeOptions.css'
import Empty from './Empty'

const ThreeOptions = (props) => {
 
    const clickOption = (index) => {
        if (props.clickOption)
        {
            props.clickOption (index)
        }
    }

    return (  
      <div className = 'ThreeOptions_ContentView' key = 'ThreeOptions_ContentView'>
           <div className = {props.selectedIndex === 1 ? 'ThreeOptions_OptionViewSelected': 'ThreeOptions_OptionView'} key = 'topView' onClick = { () => { clickOption(1) } }>
                <div className = 'ThreeOptions_OptionViewTopTitle' key = 'firstTopTitle'>{props.firstTopTitle}</div>
                <Empty 
                    height = '4px'
                    key = 'firstTopTitleEmpty'
                />
                <div className = 'ThreeOptions_OptionViewBottomTitle' key = 'firstBottomTitle'>{props.firstBottomTitle}</div>
           </div>
           <div style = {{width : '1px', height : '50%', backgroundColor : 'rgb(235, 235, 235)'}} key = 'firstLine'/>
           <div className = {props.selectedIndex === 2 ? 'ThreeOptions_OptionViewSelected': 'ThreeOptions_OptionView'} key = 'middleView' onClick = { () => { clickOption(2) }}>
                <div className = 'ThreeOptions_OptionViewTopTitle' key = 'secondTopTitle'>{props.secondTopTitle}</div>
                <Empty 
                    height = '4px'
                    key = 'secondTopTitleEmpty'
                />
                <div className = 'ThreeOptions_OptionViewBottomTitle' key = 'secondBottomTitle'>{props.secondBottomTitle}</div>
           </div> 
           <div style = {{width : '1px', height : '50%', backgroundColor : 'rgb(235, 235, 235)'}} key = 'secondLine'/>
           <div className = {props.selectedIndex === 3 ? 'ThreeOptions_OptionViewSelected': 'ThreeOptions_OptionView'} key = 'bottomView' onClick = { () => { clickOption(3) }}>
                <div className = 'ThreeOptions_OptionViewTopTitle' key = 'thirdTopTitle'>{props.thirdTopTitle}</div>
                <Empty 
                    height = '4px'
                    key = 'thirdTopTitleEmpty'
                />
                <div className = 'ThreeOptions_OptionViewBottomTitle' key = 'thirdBottomTitle'>{props.thirdBottomTitle}</div>
           </div>
      </div>
    )
}

export default ThreeOptions