import React, { Component } from 'react'
import '../../css/Main.css'
 
import Empty                from '../../view/Empty'
 
import { logout } from '../../data/ManagerUserProfileEntity'
import { startConnection } from '../../api/httpEngine/HttpEngine'
import { optionViewModel, showTips } from '../../utils/utils'
import LogoutHttpItem from '../../api/httpItem/LogoutHttpItem'
import LeftAvatarRightTitle from '../../view/LeftAvatarRightTitle'
import OneRightButton from '../../view/OneRightButton'
import OneLine from '../../view/OneLine'
import { createFromIconfontCN } from '@ant-design/icons';
import { kIconFontUrl } from '../../global/data';
import LeftIconRightTitle from '../../view/LeftIconRightTitle'

import { AreaRouterList } from '../../router/BaseRouter'
import PlayerView from '../../view/PlayerView'
import PlayerEngine from '../../core/PlayerEngine'


class Main extends Component {
    state = {
        leftOptionArray: [],
    }
 
    IconFont = createFromIconfontCN({
        scriptUrl: [
          kIconFontUrl
        ],
    });

    componentDidMount = () => {

        let subOption = null

        let tmp = []
        subOption = []
        subOption.push (optionViewModel('icon-icon-test' , '列表音乐', '/main/musicList', [], true)) 
        // subOption.push (optionViewModel('icon-zhuanji' , '专辑', '/main/user/certificate', [], false)) 
        // subOption.push (optionViewModel('icon-MV' , 'MV', '/main/user/certificate1', [], false)) 
        // subOption.push (optionViewModel('icon-jiarugedan' , '歌单', '/main/user/certificate2', [], false)) 
        tmp.push (optionViewModel ('icon-yonghuguanli', '推荐', '/main/user',subOption))

        // subOption = []
        // subOption.push (optionViewModel('icon-renxiang' , '歌手', '/main/common/express', [], false)) 
        // tmp.push (optionViewModel ('icon-tuichudenglu', '歌手', '/main/common',subOption)) 

        subOption = []
        subOption.push (optionViewModel('icon-xiai' , '喜欢列表', '/main/myCollectionList', [], false)) 
        tmp.push (optionViewModel ('icon-tuichudenglu', '我的', '/main/mine',subOption)) 

        this.setState({leftOptionArray : tmp})
    } 

    //点击菜单
    clickMenuView = (data) =>{
        let tmp = this.state.leftOptionArray
        for (let i = 0 ; i < tmp.length ; i++)
        {
            let option = tmp[i]
            let child = option.optionSubOption
            if (child.length > 0)
            {
                for (let j = 0 ; j < child.length; j++)
                {
                    let childData = child[j]
                    childData.isChoosed = false
                    if (childData.optionType === data.data.optionType)
                    {
                        childData.isChoosed = true
                        break
                    }
                }
            }
        }
        let tmpLeftOptionArray = JSON.parse(JSON.stringify(tmp))
        this.setState ({leftOptionArray : tmpLeftOptionArray})
    }
 
    optionList = () => {
        let jsxArray = []
        if (this.state.leftOptionArray.length > 0)
        {
            for (let i = 0 ; i < this.state.leftOptionArray.length ; i++)
            {
                let option = this.state.leftOptionArray[i]
                let child = option.optionSubOption
                jsxArray.push (
                    <div className='Main_MainTitleView' key = {'Main_MainTitleView' + i}>
                        <h1 className='Main_MainTitle'>{option.optionTitle}</h1>
                    </div>
                )
                    
                if (child.length > 0)
                {
                    for (let j = 0 ; j < child.length; j++)
                    {
                        let childData = child[j]
                        jsxArray.push (
                            <Empty height = '10px' key = {'optionEmpty' + j + i}/>,
                            <div className='Main_SubTitleView' key = {'Main_SubTitleView' + j + i}>
                                <LeftIconRightTitle 
                                    data = {childData}
                                    key = {'Main_SubTitle' + j + i} 
                                    iconName = {childData.optionIcon}
                                    optionName = {childData.optionTitle}
                                    clickView = {this.clickMenuView}
                                />
                            </div>
                        )
                    }
                }

                jsxArray.push (
                    <Empty height = '46px' key = {'lastOptionEmpty' + i}/>
                )
            }
        }
        return jsxArray
    }

    clickLogoutButton = () => {
        let logoutItem = new LogoutHttpItem()
        startConnection (logoutItem).then ( response => {
            showTips (response.result.msg, 0)
            logout ()
        }, error => {

        })
    }

    render() {
        return (
            <div className = 'Main_bgView'>
                <div className = 'Main_contentView'> 
                    <div className = 'Main_leftOptionView'> 
                        <Empty height = '40px' key = 'Main_leftOptionView'/>
                        <LeftAvatarRightTitle key = 'LeftAvatarRightTitle'/>
                        <Empty height = '10px' key = 'LeftAvatarRightTitleEmpty'/>
                        <OneRightButton
                            callback = {this.clickLogoutButton}
                            key = 'OneRightButton'
                        />
                        <Empty height = '10px' key = 'OneRightButtonEmpty'/>
                        <OneLine key = 'OneLine'/>
                        <Empty height = '46px' key = 'OneLineEmpty'/>
                        <div className='Main_optionList'>
                            {this.optionList()}
                        </div>
                    </div>
                    <div className = 'Main_rightOptionView'>
                        <div className = 'Main_rightOptionContentView'>
                            <AreaRouterList />
                        </div>
                        <div className = 'Main_playerView'>
                            <PlayerView

                            />
                        </div>
                    </div>
                    <PlayerEngine/>
                </div>
            </div>
        )
    }
}

export default Main