import { Image } from 'antd'
import '../css/FilterView.css'
import Empty from '../view/Empty'
import LeftIconRightTitleBorder from './LeftIconRightTitleBorder'

const FilterView = (props) => {
  
    const clickRightView = () => {
        if (props.clickRightView)
        {
            props.clickRightView (props)
        }
    }

    return(
        <div className = 'FilterView_ContentView'>
            <div className = 'FilterView_TopView'>
                <Image
                    className = "FilterView_Avatar"
                    src = {props.avatar}
                    preview = {false}
                    key = 'FilterView_Avatar'
                />
                <div className = 'FilterView_OptionView' key = 'FilterView_OptionView'>
                    <div className = 'FilterView_TopTitleView'>
                        <h1 className = 'FilterView_TopTitle' key = 'topTitle'>{props.topTitle}</h1>
                    </div>
                    <Empty height = '4px' key = 'Empty'/>
                    <div className = 'FilterView_BottomTitleView'>
                        <h1 className = 'FilterView_BottomTitle' key = 'bottomTitle'>{props.bottomTitle}</h1>
                    </div>
                </div> 
                <div className = 'FilterView_RightView'>
                    <LeftIconRightTitleBorder
                        optionName = '只听他的'
                        iconName = 'icon-shunxu'
                        key = 'LeftIconRightTitleBorder'
                        clickView = {clickRightView}
                    />
                </div>
            </div>
            <div className = 'FilterView_BottomLine'>

            </div>
        </div>
    )
}

export default FilterView