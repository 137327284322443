import '../httpItem/BaseHttpItem'
import axios from 'axios'
import { kHttpTimeOut } from '../../global/data'
import { showTips } from '../../utils/utils'

import { isLogin , currentToken , currentUserId } from '../../data/ManagerUserProfileEntity'
 
axios.defaults.timeout = kHttpTimeOut
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

export const startConnection = (httpItem) => {
    return new Promise ( (resolve , reject) => {
        let reqeustUrl = httpItem.httpReqeustDomainUrl + httpItem.httpRequestUrl
        if (httpItem.httpRequestMethod === 'get')
        {
            axios.get(reqeustUrl).then( (response) => {
                resolve (response)
            })
            .catch( (error) => { 
                reject (error)
            });
        }
        else if (httpItem.httpRequestMethod === 'post')
        {
            axios.post(reqeustUrl , httpItem.httpRequestPostParams).then( (response) => {
                resolve(response)
            }).catch( (error) => {
                reject(error)
            });
        } 
    })
}

axios.interceptors.request.use( (request) =>{ 
    if (isLogin())
    {   
        request.headers['token'] = currentToken()
        request.headers['userid'] = currentUserId()
    } 
    request.headers['platform'] = 'h5'
    return request;
},(error) => {
    return Promise.reject(error);
});
   
axios.interceptors.response.use( (response) => {
    let responseData = response.data;
    if (responseData.code === 1)
    { 
        showTips (responseData.msg , 2)
        return Promise.reject('')
    }
    else if (responseData.code === 10001)
    {
        showTips ('请重新登录' , 2)
        
        window.sessionStorage.setItem('token','')
        window.sessionStorage.setItem('userId', '')

        setTimeout(() => {
            window.location.href = '/login'
            return Promise.reject (response)
        }, 1500);
    }
    else
    {
        response['result'] = response['data']
        return response;
    }
},(error) => {  
    showTips ('网络异常' , 2)
    return Promise.reject(error)
});