
import '../css/PlayerView.css'

import { Image, Slider } from 'antd'
import OneIcon from '../view/OneIcon'
import WidthEmpty from '../view/WidthEmpty'
import { connect } from 'react-redux'
import { kModeChanged, kNextMusic, kPlayStatusChanged, kPreviewMusic, kProgressChanged, kStatusHasChanged, kVolumeChanged } from '../redux/reducer/playReducer'
import { isNull, showTips } from '../utils/utils'
import { useEffect , useState } from 'react'
import Empty from './Empty'
import LeftIconRightTitleBorder from './LeftIconRightTitleBorder'
import CancelFavorMusicHttpItem from '../api/httpItem/CancelFavorMusicHttpItem'
import FavorMusicHttpItem from '../api/httpItem/FavorMusicHttpItem'
import { startConnection } from '../api/httpEngine/HttpEngine'

const PlayerView = (props) => { 

    const [volumeIcon, setVolumeIcon] = useState('')

    const showtipsFormatter = () => {
        return props.player.currentTimeStr
    }

    const volumeTips = () => {
        return props.player.volume * 100 + '%'
    }

    //暂停、播放
    const changedPlayStatus = () => {
        if (isNull(props.player.songid) || props.player.songid.length === 0)
        {
            showTips ('请先选择一首歌曲',2)
            return
        }
        props.changedPlayStatus()
    }

    //下一首
    const clickNextButton = () => {
        if (isNull(props.player.playListDataSource) || props.player.playListDataSource.length === 0)
        {
            showTips ('当前暂无可播放列表',2)
            return
        }
        props.clickNextButton()
    }

    //上一首
    const clickPreview = () => {
        if (isNull(props.player.playListDataSource) || props.player.playListDataSource.length === 0)
        {
            showTips ('当前暂无可播放列表',2)
            return
        }
        props.clickPreview()
    }

    //点赞
    const clickLikeIcon = () => {
        if (isNull(props.player.songid) || props.player.songid.length === 0)
        {
            showTips ('请先选择一首歌曲',2)
            return
        } 
        if (parseInt(props.player.status) === 1)
        {
            //取消收藏
            let cancelFavorMusicHttpItem = new CancelFavorMusicHttpItem (props.player.songid)
            startConnection (cancelFavorMusicHttpItem).then (response => {
                showTips ('取消收藏成功',0) 
                props.statusChanged(0)
            } , error => {

            })
        }
        else
        {
            //收藏
            let data = {
                songid : props.player.songid,
                author : props.player.author,
                pic : props.player.pic,
                title : props.player.title,
                duration : props.player.duration,
            }
            let favorMusicHttpItem = new FavorMusicHttpItem (data)
            startConnection (favorMusicHttpItem).then (response => {
                showTips ('收藏歌曲成功',0)
                props.statusChanged(1)
            } , error => {

            })
        }
    }

    //滑动进度条
    const sliderOnChanged = (value) => {
        if (isNull(props.player.songid) || props.player.songid.length === 0)
        {
            showTips ('请先选择一首歌曲',2)
            return
        }
        props.sliderOnChanged(value)
    }

    useEffect(() => {
        if (parseFloat(props.player.volume) === 0.0)
        {
            setVolumeIcon ('icon-volumeCross')
        }
        else if (parseFloat(props.player.volume) >= 0.1 && parseFloat(props.player.volume) <= 0.5)
        {
            setVolumeIcon ('icon-volumeLow')
        }
        else if (parseFloat(props.player.volume) >= 0.6 && parseFloat(props.player.volume) <= 0.9)
        {
            setVolumeIcon ('icon-volumeMiddle')
        }
        else if (parseFloat(props.player.volume) === 1.0)
        {
            setVolumeIcon ('icon-volumeHigh')
        }
    }, [props.player.volume])

    return(
        <div className='PlayerView_ContentView'>
            <Empty height = '10px'/>
            <div className = 'PlayerView_TopView'>
                <div className = 'PlayerView_TopLeftView'>
                    <WidthEmpty
                        width = '20px'
                        height = '1px'
                    />
                    <Image 
                        className='PlayerView_Cover'
                        src = {props.player.pic}
                    />
                    <WidthEmpty
                        width = '20px'
                        height = '1px'
                    />
                    <div className='PlayerView_MusicInfo'>
                        <div className='PlayerView_MusicNameView'>
                            <h1 className='PlayerView_MusicName'>{props.player.title}</h1>
                        </div>
                        <div className='PlayerView_SingerNameView'>
                            <h1 className='PlayerView_SingerName'>{props.player.author}</h1>
                        </div>
                    </div>
                    <WidthEmpty
                        width = '20px'
                        height = '1px'
                    />
                </div>
                <div className = 'PlayerView_TopCenterView'>
                    <div className = 'PlayerView_Operated'>
                            <div style = {{width : '95px', height : '40px'}}>
                                <LeftIconRightTitleBorder
                                    optionName = {props.player.modeDesc}
                                    iconName = {props.player.modeIcon}
                                    key = 'mode'
                                    clickView = {props.changedMode}
                                />
                            </div> 
                            <WidthEmpty
                                width = '40px'
                                height = '1px'
                            />
                        <OneIcon
                            width = '26px'
                            height = '26px'
                            fontSize = '26px'
                            textColor = 'rgb(51, 51, 51)'
                            iconName = 'icon-shangyishou'
                            clickView = {clickPreview}
                        />
                        <WidthEmpty
                            width = '30px'
                            height = '1px'
                        />
                        <OneIcon
                            width = '54px'
                            height = '54px'
                            fontSize = '46px'
                            textColor = 'rgb(51, 51, 51)'
                            iconName = {props.player.isPlaying ? 'icon-pausecircle-fill' : 'icon-play-fill'}
                            clickView = {changedPlayStatus}
                        />
                        <WidthEmpty
                            width = '30px'
                            height = '1px'
                        />
                        <OneIcon
                            width = '26px'
                            height = '26px'
                            fontSize = '26px'
                            textColor = 'rgb(51, 51, 51)'
                            iconName = 'icon-xiayishou'
                            clickView = {clickNextButton}
                        />
                        <WidthEmpty
                            width = '40px'
                            height = '1px'
                        />
                        <OneIcon
                            width = '30px'
                            height = '30px'
                            fontSize = '30px'
                            textColor = 'rgb(51, 51, 51)'
                            iconName = {parseInt(props.player.status) === 1 ? 'icon-w_aixin' : 'icon-xihuan'}
                            clickView = {clickLikeIcon}
                        />
                    </div>
                </div>
                <div className = 'PlayerView_TopRightView'>
                    <div className = 'PlayerView_VolumnView'>
                        <OneIcon
                            width = '24px'
                            height = '24px'
                            fontSize = '24px'
                            textColor = 'rgb(51, 51, 51)'
                            iconName = {volumeIcon}
                        />
                        <Slider
                            className = 'PlayerView_Slider'
                            trac
                            min = {0}
                            max = {1}
                            step = {0.1}
                            value = {props.player.volume}
                            showtipsFormatter = {false}
                            onChange = {props.volumeChanged}
                            tipFormatter = {volumeTips}
                        />
                    </div>
                    <WidthEmpty
                        width = '30px'
                        height = '1px'
                    />
                </div>
            </div>
            <Empty height = '10px'/>
            <div className = 'PlayerView_BottomView'>
                <div className = 'PlayerView_PlayInfo'>
                    <div className='PlayerView_TimeView'>
                        <h1 className='PlayerView_time'>{props.player.currentTimeStr}</h1>
                    </div>
                    <WidthEmpty
                        width = '10px'
                        height = '1px'
                    />
                    <Slider
                        className = 'PlayerView_Slider'
                        min = {0}
                        max = {1}
                        step = {0.00001}
                        value = {props.player.percent}
                        showtipsFormatter = {false}
                        onChange = {sliderOnChanged}
                        tipFormatter = {showtipsFormatter}
                    />
                    <WidthEmpty
                        width = '10px'
                        height = '1px'
                    />
                    <div className='PlayerView_TimeView'>
                        <h1 className='PlayerView_time'>{props.player.durationStr}</h1>
                    </div>  
                </div>
            </div>
            <Empty height = '10px'/>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return { 
        //改变播放状态
        changedPlayStatus : () => {
            dispatch ({type : kPlayStatusChanged})
        },

        //下一首
        clickNextButton : () => {
            dispatch ({type : kNextMusic})
        },  

        //上一首
        clickPreview : () => {
            dispatch ({type : kPreviewMusic})
        },

        //播放模式改变
        changedMode : () => {
            dispatch ({type : kModeChanged})
        },

        //拖动进度条
        sliderOnChanged : (value) => { dispatch ({type : kProgressChanged, value : value}) },

        //音量
        volumeChanged : (value) => { dispatch( {type : kVolumeChanged, value : value} )},

        //歌曲收藏状态改变
        statusChanged : (status) => {
            dispatch ({type : kStatusHasChanged, status: status})
        },
    }
}

const mapStateToProps = (state) => {
    return state
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayerView)
