import '../css/Empty.css'
 
const Empty = (props) => {

    let innerStyle = {height : props.height, width : '100%' , backgroundColor: props.backgroundColor}

    return(
        <div style={innerStyle}></div>
    )
}

export default Empty