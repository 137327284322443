import '../css/ListViewHeadView.css'
 
const ListViewHeadView = (props) => {
  
    return(
        <div className = 'ListViewHeadView_ContentView' style = {{backgroundColor : props.backgroundColor}}>
            <div className='ListViewHeadView_TopView'>
                <div style = {{width : '20px'}} />
                <div className = 'ListViewHeadView_OptionView' key = 'ListViewHeadView_OptionView'>
                    <div className = 'ListViewHeadView_Option' style = {{width : '30%'}} key = {1}>
                        <h1 className = 'ListViewHeadView_Title' key = 'ListViewHeadView_Title1'>{props.firstTitle}</h1>
                    </div>
                    <div className = 'ListViewHeadView_Option' style = {{width : '30%'}} key = {2}>
                        <h1 className = 'ListViewHeadView_Title' key = 'ListViewHeadView_Title2'>{props.secondTitle}</h1>
                    </div>
                    <div className = 'ListViewHeadView_Option' style = {{width : '10%'}} key = {0}>
                        <h1 className = 'ListViewHeadView_Title' key = 'ListViewHeadView_Title3'>{props.thirdTitle}</h1>
                    </div>
                    <div className = 'ListViewHeadView_Option' style = {{width : '10%'}} key = {3}>
                        <h1 className = 'ListViewHeadView_Title' style = {{textAlign : 'center'}} key = 'ListViewHeadView_Title4'>{props.fourthTitle}</h1>
                    </div>
                    <div className = 'ListViewHeadView_Option' style = {{width : '10%'}} key = {4}>
                        <h1 className = 'ListViewHeadView_Title' style = {{textAlign : 'center'}} key = 'ListViewHeadView_Title5'>{props.fifthTitle}</h1>
                    </div> 
                    <div className = 'ListViewHeadView_Option' style = {{width : '10%'}} key = {5}>
                        <h1 className = 'ListViewHeadView_Title' style = {{textAlign : 'center'}} key = 'ListViewHeadView_Title6'>{props.sixthTitle}</h1>
                    </div> 
                </div> 
            </div>
            <div className='ListViewHeadView_BottomView' />
        </div>
    )
}

export default ListViewHeadView