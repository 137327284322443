import { currentUserId } from "../../data/ManagerUserProfileEntity"
import BaseHttpItem from "./BaseHttpItem"

export default class MyMusicCollectionListHttpItem extends BaseHttpItem{
    constructor (){ 
        super()
        this.descriptionItem = '获取音乐播放链接'
        this.httpRequestUrl = 'myMusic/myMusicCollectionList?userId='+currentUserId()
        this.httpRequestMethod = 'get' 
    }
}