import '../../css/MvList.css'
import React, { useState , useEffect } from 'react';
import { isNull, showTimeFormatter, showTips } from '../../utils/utils';
import { startConnection } from '../../api/httpEngine/HttpEngine';
import MvListHttpItem from '../../api/httpItem/MvListHttpItem';
import MvView from '../../view/MvView';
import Empty from '../../view/Empty';
import VideoPlayer from '../common/VideoPlayer';
import { connect } from 'react-redux'
import { kIsPause, kIsPlaying, kSongidChanged, kStatusHasChanged } from '../../redux/reducer/playReducer'
import MvInfoHttpItem from '../../api/httpItem/MvInfoHttpItem';

const MvList = (props) => {
  
    const [dataSource, setDataSource] = useState([])
    const [startIndex, setStartIndex] = useState(0)
    const [hasLoadMore, setHasLoadMore] = useState(false)
    const [isShowVideoPlayView, setIsShowVideoPlayView] = useState(false)
    const [videoPlayerUrl, setVideoPlayerUrl] = useState('')

    useEffect(() => {
        if (!isNull(props.singerId) && props.singerId.length > 0)
        {
            reloadData()  
        } 
    }, [props.singerId])

    useEffect(() => {
        reloadData()  
    }, [startIndex])
    
    const reloadData = () => {
        let mvListHttpItem = new MvListHttpItem(props.singerId, startIndex)
        startConnection (mvListHttpItem).then (response => {
            setHasLoadMore (response.result.data.length === 10)
            let tmp = dataSource
            tmp = tmp.concat(response.result.data)
            setDataSource (tmp)
        },error => {

        })
    }
  
    const clickLoadMoreData = () => {
        let dataCount = dataSource.length
        setStartIndex (dataCount)
    }

    const clickView = (data) => {
        if (!isNull(data.data.mvId) && data.data.mvId.length > 0)
        {
            let mvUrlHttpItem = new MvInfoHttpItem(data.data.mvId)
            startConnection (mvUrlHttpItem).then (response => {
                setVideoPlayerUrl (response.result.data)
                setIsShowVideoPlayView (true)
                props.pause ()
            },error => {

            })   
        }
    }

    const clickVideoPlayerClose = () => {
        setVideoPlayerUrl ('')
        setIsShowVideoPlayView (false)
        if (!isNull(props.player.songid) && props.player.songid.length > 0)
        {
            props.play()
        }
    }

    const mvList = () => {
        let jsxArray = []
        for (let i = 0 ; i < dataSource.length ; i++)
        {
            let mvData = dataSource[i]
            jsxArray.push (
                <div className = 'MvList_MVView' key = {mvData.mvId + i}>
                    <MvView 
                        mvPic = {mvData.mvPic}
                        mvTitle = {mvData.mvTitle}
                        mvSingerName = {mvData.mvSingerName}
                        key = {mvData.mvId}
                        data = {mvData}
                        clickView = {clickView}
                    />
                </div>
            )
        }
        return jsxArray
    }

    const loadMore = () => {
        let jsxArray = []
        if (hasLoadMore)
        { 
            jsxArray.push (
                <div className = 'MvList_LoadMore' key = 'MvList_LoadMore'>
                    <Empty 
                        height = '5px'
                        key = 'topSpace'
                    />
                    <div className = 'MvList_LoadMoreButton' key='loadMore' onClick = {clickLoadMoreData}>{'加载更多，已加载('+dataSource.length+'个MV)'}</div>
                    <Empty 
                        height = '5px'
                        key = 'bottomSpace'
                    />
                </div>
            )
        }
        return jsxArray
    }

    return(
        <div className = 'MvList_BgView' key = 'MvList_BgView'>
            <div className = 'MvList_MVList' key = 'MvList_MVList'>
                {mvList()}
                {loadMore()}
            </div>
            <VideoPlayer
                closeIcon = 'icon-round_close_light'
                VideoPlayer = {videoPlayerUrl}
                isShow = {isShowVideoPlayView}
                clickCloseView = {clickVideoPlayerClose}
                key = 'VideoPlayer' 
            />
        </div>
    )
} 

const mapStateToProps = (state) => {
    return state
}

const mapDispatchToProps = (dispatch) => {
    return {
        //暂停
        pause : () => {
            dispatch ({type : kIsPause})
        },

        //播放
        play : () => {
            dispatch ({type : kIsPlaying})
        },
    }
}

export default connect( mapStateToProps ,mapDispatchToProps )(MvList)