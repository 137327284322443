import '../css/OneRightButton.css'

import { createFromIconfontCN } from '@ant-design/icons';
import { kIconFontUrl } from '../global/data';

const OneRightButton = (props) => {
    
    const cb = (currentProps) => 
    {
        if (props.callback)
        {
            props.callback(currentProps)
        }
    }


    const IconFont = createFromIconfontCN({
        scriptUrl: [
          kIconFontUrl
        ],
    });
 
    return(
        <div className='OneRightButton_ContentView'>
            <IconFont type='icon-tuichudenglu1' className = 'OneRightButton_Button' onClick={cb}/> 
        </div>
    )
}

export default OneRightButton