import { Image } from 'antd'
import '../css/AlbumView.css'

const AlbumView = (props) => {
 
    const clickView = () => {
        if (props.clickView)
        {
            props.clickView (props)
        }
    }

    return(
        <div className = 'AlbumView_ContentView' key = 'AlbumView_ContentView' onClick = {clickView}>
            <div className = 'AlbumView_LeftView' key = 'AlbumView_LeftView'>
                <div className = 'AlbumView_AlbumName' key = 'albumName'>{props.albumName}</div>
                <div className = 'AlbumView_AlbuReleaseTime' key = 'albuReleaseTime'>{props.albuReleaseTime}</div>
            </div>
            <Image
                className = "AlbumView_AlbumAvatar"
                src = {props.imageUrl}
                preview = {false}
                key = 'FilterView_Avatar'
            />
        </div>
    )
}

export default AlbumView