import '../css/SingerHeadView.css'
import { Image } from 'antd'
import WidthEmpty from '../view/WidthEmpty'

const SingerHeadView = (props) => {

    return (  
      <div className = 'SingerHeadView_ContentView' style = {{height : props.height}}>
            <div className = 'SingerHeadView_LeftAvatar'>
                <Image
                    className = 'SingerHeadView_Avatar'
                    src = {props.pic}
                    alt = '查看大图'
                    preview = {false}
                />
            </div>
            <WidthEmpty height = {props.height} width = '20px'/>
            <div className = 'SingerHeadView_RightDescription'>
                <h1 className = 'SingerHeadView_RightTitle'>{props.description}</h1>
            </div>  
      </div>
    )
}

export default SingerHeadView