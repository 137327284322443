import '../../css/AlbumMusicList.css'
import React, { useState , useEffect } from 'react';
import { isNull, showTimeFormatter, showTips } from '../../utils/utils';
import SingerListHttpItem from '../../api/httpItem/SingerListHttpItem'
import SingerMusicView from '../../view/SingerMusicView';
import { startConnection } from '../../api/httpEngine/HttpEngine';
import FavorMusicHttpItem from '../../api/httpItem/FavorMusicHttpItem';
import CancelFavorMusicHttpItem from '../../api/httpItem/CancelFavorMusicHttpItem';
import { connect } from 'react-redux'
import { kIsPause, kIsPlaying, kSongidChanged, kStatusHasChanged } from '../../redux/reducer/playReducer'
import AlbumMusicListHttpItem from '../../api/httpItem/AlbumMusicListHttpItem';
import OneIcon from '../../view/OneIcon';
import OneCenterTitle from '../../view/OneCenterTitle';
import Empty from '../../view/Empty';

const AlbumMusicList = (props) => {
  
    const [musicList, setMusicList] = useState([])

    useEffect(() => {
        if (!isNull(props.albumId) && props.albumId.length > 0)
        {
            reloadData()  
        } 
    }, [props.albumId])
    
    const reloadData = () => {
        let albumMusicListHttpItem = new AlbumMusicListHttpItem(props.albumId)
        startConnection (albumMusicListHttpItem).then (response => {
            setMusicList (response.result.data)
        },error => {

        })
    }

    const clickStatusIcon = (data) => {
        if (parseInt(data.data.status) === 1)
        {
            //取消收藏
            let cancelFavorMusicHttpItem = new CancelFavorMusicHttpItem (data.data.songid)
            startConnection (cancelFavorMusicHttpItem).then (response => {
                showTips ('取消收藏成功',0)
                data.data.status = 0
                if (data.data.songid === props.player.songid)
                {
                    //如果当前改变的是正在播放的歌曲
                    props.statusChanged(0)
                }
                for (let i = 0 ; i < musicList.length; i++)
                {
                    let musicData = musicList[i]
                    if (musicData.songid === data.data.songid)
                    {
                        musicData.status = '0'
                        break
                    }
                }
                let tmpDataSources = JSON.parse(JSON.stringify(musicList))
                setMusicList(tmpDataSources)
            } , error => {

            })
        }
        else
        {
            //收藏
            let favorMusicHttpItem = new FavorMusicHttpItem (data.data)
            startConnection (favorMusicHttpItem).then (response => {
                showTips ('收藏歌曲成功',0)
                data.data.status = 1
                if (data.data.songid === props.player.songid)
                {
                    //如果当前改变的是正在播放的歌曲
                    props.statusChanged(1)
                }
                for (let i = 0 ; i < musicList.length; i++)
                {
                    let musicData = musicList[i]
                    if (musicData.songid === data.data.songid)
                    {
                        musicData.status = '1'
                        break
                    }
                }
                let tmpDataSources = JSON.parse(JSON.stringify(musicList))
                setMusicList(tmpDataSources)
            } , error => {

            })
        }
    }

    const clickView = (data) => {
        if (props.player.songid === data.data.songid)
        {
            if (props.player.isPlaying)
            {
                props.pause ()
            }
            else
            {
                props.play ()
            }
            return
        }

        props.changedSongid(data.data.songid)
    }

    const musicListView = () => {
        let jsxArray = []
        if (musicList.length > 0)
        {
            jsxArray.push (
                <div style = {{marginLeft : '20px'}}>
                    <Empty 
                        height = '5px'
                    />
                    <OneCenterTitle
                        height = '40px'
                        font = '25px'
                        textColor = 'rgb(88, 88, 88)'
                        title = {props.albumName + "(含"+ musicList.length +"首歌)"}
                        textAlign = 'left'
                        key = 'OneCenterTitle'
                    />
                    <Empty 
                        height = '5px'
                    />
                </div>
            )

            for (let i = 0 ; i < musicList.length ; i++)
            {
                let musicData = musicList[i]
                jsxArray.push (
                    <SingerMusicView 
                        height = '60px'
                        key = {musicData.songid}
                        pic = {musicData.pic}
                        title = {musicData.title}
                        duration = {showTimeFormatter(musicData.duration)}
                        statusIcon = {parseInt(musicData.status) === 1 ? 'icon-w_aixin' : 'icon-xihuan'}
                        data = {musicData}
                        hasBottomLine = {i !== (musicList.length - 1)}
                        clickStatusIcon = {clickStatusIcon} 
                        clickView = {clickView}
                    />
                )
            }
        }
        return jsxArray
    }

    const clickCloseView = () => {
        if (props.clickCloseView)
        {
            props.clickCloseView()
        }
    }

    return(
        <div className = 'AlbumMusicList_BgView' style={{display: props.isShow === false ? "none" : ''}}>
            <div className = "AlbumMusicList_CloseIcon" onClick = {clickCloseView}>
                <OneIcon
                    width = '50px'
                    height = '50px'
                    fontSize = '50px'
                    textColor = 'rgb(255, 255, 255)'
                    iconName = 'icon-round_close_light'
                    clickView = {clickCloseView}
                />
            </div>
            <div className = 'AlbumMusicList_ContentView'>
                {musicListView()}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return state
}

const mapDispatchToProps = (dispatch) => {
    return {
        //暂停
        pause : () => {
            dispatch ({type : kIsPause})
        },

        //播放
        play : () => {
            dispatch ({type : kIsPlaying})
        },
 
        //切换歌曲id
        changedSongid : (songid) => {
            dispatch ({type : kSongidChanged, songid : songid})
        },

        //歌曲收藏状态改变
        statusChanged : (status) => {
            dispatch ({type : kStatusHasChanged, status: status})
        },
    }
}

export default connect( mapStateToProps ,mapDispatchToProps )(AlbumMusicList)