import '../css/TagView.css'
 
const TagView = (props) => {

    const clickView = () => {
        if (props.clickView)
        {
            props.clickView (props)
        }
    }

    return(
        <div className = 'TagView_TagView' onClick = {clickView}>
            <div className = 'TagView_TagTitleView'>
                <h1 className = 'TagView_TagTitle'>{props.optionTitle}</h1>
            </div>
            <div className = {props.isChoosed ? 'TagView_LineSelect': 'TagView_Line'}></div>
        </div>
    )
}

export default TagView