import BaseHttpItem from "./BaseHttpItem"

export default class CancelFavorMusicHttpItem extends BaseHttpItem{
    constructor (songId){ 
        super()
        this.descriptionItem = '取消收藏音乐'
        this.httpRequestUrl = 'myMusic/musicUncollection'
        this.httpRequestMethod = 'post' 
        this.httpRequestPostParams = {
            songid : songId
        }
    }
}