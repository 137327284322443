import '../css/OneLine.css'
 
const OneLine = (props) => {
    
    return(
        <div className='OneLine_ContentView'>
            <div className='OneLine_Line'></div>
        </div>
    )
}

export default OneLine