import '../css/OneCenterTitle.css'

const OneCenterTitle = (props) => {
    const cb = (currentProps) => 
    {
        if (props.callback)
        {
            props.callback(currentProps)
        }
    }

    let innerStyle = {height : props.height}
    let labelInnerStyle = {fontSize : props.font , color : props.textColor, fontWeight : 'bold' , lineHeight : props.height, backgroundColor : props.backgroundColor ,textAlign : props.textAlign}

    return(
        <div style = {innerStyle}>
            <h1 style = {labelInnerStyle} className = 'OneCenterTitle_Title' onClick = { cb }> {props.title} </h1>
        </div>
    )
}

export default OneCenterTitle