import PlayerEntity from "../../data/PlayerEntity"
import { showTimeFormatter } from "../../utils/utils"

export const kSongidChanged = 'KSONGIDCHANGED' //歌曲id有改变
export const kCheckMusicUrl = 'KCHECKMUSICURL' //播放链接
export const kIsPlaying     = 'KISPLAYING'    //播放
export const kIsPause       = 'KISPAUSE  '    //暂停
export const kPlayStatusChanged = 'KPLAYSTATUSCHANGED' //播放状态改变（暂停、播放）
export const kPlayProgress = 'KPLAYPROGRESS' //播放进度
export const kPlayDuration = 'KPLAYDURATION' //播放总时长
export const kPlayInfo = 'KPALYINFO' //音乐数据
export const kProgressChanged = 'KPROGRESSCHANGED' //音乐进度有改动
export const kStartProgressChanged = 'KSTARTPROGRESSCHANGED' //音乐进度有改动
export const kEndProgressChanged = 'kENDPROGRESSCHANGED' //音乐进度有改动
export const kVolumeChanged = 'kVOLUMECHANGED' //音乐音量有改动
export const kMusicDataSourceChanged = 'kMUSICDATASOURCECHANGED'//音乐播放数据有改动
export const kModeChanged = 'KMODECHANGED' //音乐播放模式有改动
export const kNextMusic = 'KNEXTMUSIC' //下一首
export const kPreviewMusic = 'KPREVIEWMUSIC' //上一首
export const kEndReplayMusic = 'KENDREPLAYMUSIC' //结束重复播放
export const kStatusHasChanged = 'kSTATUSHASCHANGED' //音乐收藏状态有改变

const initState = {
    player : new PlayerEntity (),
    type : '',
}

const playReducer = (state = initState, data) => {
    let tmpState = state
    tmpState.type = data.type
    switch (data.type) 
    {
        case kSongidChanged:
            tmpState.player.songid = data.songid
            break
        case kCheckMusicUrl:
            tmpState.player.url = data.url
            tmpState.player.isPlaying = true
            if (tmpState.player.mode === 2)
            {
                //如果当前是随机模式 需要除去当前这个音乐所占的随机数
                let randomIndex = 0
                let randomPlayListCountDataSource = tmpState.player.randomPlayListCountDataSource
                for (let i = 0 ; i < tmpState.player.playListDataSource.length ; i++)
                {
                    let musicData = tmpState.player.playListDataSource[i]
                    if (musicData.songid === tmpState.player.songid)
                    {
                        randomIndex = i
                        break
                    }
                }
                randomPlayListCountDataSource.splice (randomIndex, 1)
                tmpState.player.randomPlayListCountDataSource = randomPlayListCountDataSource
            }
            break
        case kIsPlaying:
            tmpState.player.isPlaying = true
            break
        case kPlayStatusChanged:
            tmpState.player.isPlaying = !tmpState.player.isPlaying
            break
        case kPlayProgress:
            let progress = data.progress
            let realCurrentTitme = progress.playedSeconds.toFixed(0)
            tmpState.player.currentTime = realCurrentTitme
            tmpState.player.currentTimeStr = showTimeFormatter (realCurrentTitme)
            if (parseInt(tmpState.player.duration) > 0)
            {
                //如果当前总时长有的情况下
                let percent = parseFloat(realCurrentTitme) / parseFloat(tmpState.player.duration)
                tmpState.player.percent = percent
            }
            break
        case kPlayDuration:
            let realDuration = data.duration.toFixed(0)
            tmpState.player.duration = realDuration
            tmpState.player.durationStr = showTimeFormatter (realDuration)
            break
        case kIsPause:
            tmpState.player.isPlaying = false
            break
        case kPlayInfo:
            let info = data.data
            tmpState.player.status = info.status
            tmpState.player.pic = info.pic
            tmpState.player.title = info.title
            tmpState.player.author = info.author
            break
        case kProgressChanged:
            tmpState.player.percent = data.value
            tmpState.player.shouldSeekTime = true
            break
        case kStartProgressChanged:
            break
        case kEndProgressChanged:
            tmpState.player.shouldSeekTime = false
            break
        case kVolumeChanged:
            tmpState.player.volume = data.value
            break
        case kMusicDataSourceChanged:
            tmpState.player.playListDataSource = data.musicList
            //如果是随机模式
            let randomPlayListCountDataSource = []
            for (let i = 0 ; i < data.musicList.length;i++)
            {
                let musicData = data.musicList[i]
                randomPlayListCountDataSource.push (musicData)
            }
            tmpState.player.randomPlayListCountDataSource = randomPlayListCountDataSource
            break
        case kModeChanged:
            let currentMode = state.player.mode
            if (parseInt(currentMode) === 2)
            {
                currentMode = 0
            }
            else
            {
                currentMode++
            }
            tmpState.player.mode = currentMode

            let modeIcon = ''
            let modeDesc = ''
            switch (parseInt(tmpState.player.mode))
            {
                //0顺序播放 1单曲循环 2随机播放
                case 0:
                    modeIcon = 'icon-shunxu'
                    modeDesc = '顺序播放'
                    break
                case 1:
                    modeIcon = 'icon-xunhuanbofang'
                    modeDesc = '循环播放'
                    break
                case 2:
                    modeIcon = 'icon-suiji'
                    modeDesc = '随机播放' 
                    break
                default:
                    break
            }
            tmpState.player.modeDesc = modeDesc
            tmpState.player.modeIcon = modeIcon
            break
        case kNextMusic:
            //0顺序播放 1单曲循环 2随机播放
            switch (parseInt(tmpState.player.mode))
            {
                case 0:
                    let currentIndex = 0
                    for (let i = 0 ; i < tmpState.player.playListDataSource.length; i++)
                    {
                        let musicData = tmpState.player.playListDataSource[i]
                        if (musicData.songid === tmpState.player.songid)
                        {
                            currentIndex = i
                            break
                        }
                    }
                    if (currentIndex === tmpState.player.playListDataSource.length - 1)
                    {
                        currentIndex = 0
                    }
                    else
                    {
                        currentIndex++
                    }

                    if (tmpState.player.playListDataSource.length > currentIndex)
                    {
                        let nextMusicData = tmpState.player.playListDataSource[currentIndex]
                        tmpState.player.songid = nextMusicData.songid
                    }
                    break;
                case 1:
                    tmpState.player.needReplayMusic = true
                    break
                case 2:
                    let randomIndex = parseInt(Math.random()*tmpState.player.randomPlayListCountDataSource.length);
                    let randomMusic = tmpState.player.randomPlayListCountDataSource[randomIndex]
                    tmpState.player.songid = randomMusic.songid
                    let randomPlayListCountDataSource = tmpState.player.randomPlayListCountDataSource
                    randomPlayListCountDataSource.splice (randomIndex, 1)
                    tmpState.randomPlayListCountDataSource = randomPlayListCountDataSource
                    if (randomPlayListCountDataSource.length === 0)
                    {
                        //如果当前随机播放的歌曲都播放完了 重置
                        let tmpRandomPlayListCountDataSource = []
                        for (let i = 0 ; i < tmpState.player.playListDataSource.length;i++)
                        {
                            let musicData = tmpState.player.playListDataSource[i]
                            tmpRandomPlayListCountDataSource.push (musicData)
                        }
                        tmpState.player.randomPlayListCountDataSource = tmpRandomPlayListCountDataSource
                    }
                    break
                default:
                    break;
            }
            break
        case kPreviewMusic:
            //0顺序播放 1单曲循环 2随机播放
            switch (parseInt(tmpState.player.mode))
            {
                case 0:
                    let currentIndex = 0
                    for (let i = 0 ; i < tmpState.player.playListDataSource.length; i++)
                    {
                        let musicData = tmpState.player.playListDataSource[i]
                        if (musicData.songid === tmpState.player.songid)
                        {
                            currentIndex = i
                            break
                        }
                    }
                    if (currentIndex === 0)
                    {
                        currentIndex = tmpState.player.playListDataSource.length - 1
                    }
                    else
                    {
                        currentIndex--
                    }

                    if (tmpState.player.playListDataSource.length > currentIndex)
                    {
                        let nextMusicData = tmpState.player.playListDataSource[currentIndex]
                        tmpState.player.songid = nextMusicData.songid
                    }
                    break;
                case 1:
                    tmpState.player.needReplayMusic = true
                    break
                case 2:
                    let randomIndex = parseInt(Math.random()*tmpState.player.randomPlayListCountDataSource.length);
                    let randomMusic = tmpState.player.randomPlayListCountDataSource[randomIndex]
                    tmpState.player.songid = randomMusic.songid
                    let randomPlayListCountDataSource = tmpState.player.randomPlayListCountDataSource
                    randomPlayListCountDataSource.splice (randomIndex, 1)
                    tmpState.randomPlayListCountDataSource = randomPlayListCountDataSource
                    if (randomPlayListCountDataSource.length === 0)
                    {
                        //如果当前随机播放的歌曲都播放完了 重置
                        let tmpRandomPlayListCountDataSource = []
                        for (let i = 0 ; i < tmpState.player.playListDataSource.length;i++)
                        {
                            let musicData = tmpState.player.playListDataSource[i]
                            tmpRandomPlayListCountDataSource.push (musicData)
                        }
                        tmpState.player.randomPlayListCountDataSource = tmpRandomPlayListCountDataSource
                    }
                    break
                default:
                    break;
            }
            break
        case kEndReplayMusic:
            tmpState.player.needReplayMusic = false
            tmpState.player.isPlaying = true
            break
        case kStatusHasChanged:
            tmpState.player.status = data.status
            break
        default:
            break
    }
    let realState = JSON.parse(JSON.stringify(tmpState))
    return realState
}

export default playReducer