import '../../css/SingerMusicList.css'
import React, { useState , useEffect } from 'react';
import { isNull, showTimeFormatter, showTips } from '../../utils/utils';
import SingerListHttpItem from '../../api/httpItem/SingerListHttpItem'
import SingerMusicView from '../../view/SingerMusicView';
import { startConnection } from '../../api/httpEngine/HttpEngine';
import Empty from '../../view/Empty';
import FavorMusicHttpItem from '../../api/httpItem/FavorMusicHttpItem';
import CancelFavorMusicHttpItem from '../../api/httpItem/CancelFavorMusicHttpItem';
import { connect } from 'react-redux'
import { kIsPause, kIsPlaying, kSongidChanged, kStatusHasChanged } from '../../redux/reducer/playReducer'

const SingerMusicList = (props) => {
  
    const [musicList, setMusicList] = useState([])
    const [startIndex, setStartIndex] = useState(0)
    const [hasLoadMore, setHasLoadMore] = useState(false)

    useEffect(() => {
        if (!isNull(props.singerId) && props.singerId.length > 0)
        {
            reloadData()  
        } 
    }, [props.singerId])
    
    useEffect(() => {
        reloadData()  
    }, [startIndex])

    const reloadData = () => {
        let singerListHttpItem = new SingerListHttpItem(props.singerId, startIndex)
        startConnection (singerListHttpItem).then (response => {
            setHasLoadMore (response.result.data.length === 10)
            let dataSource = musicList
            dataSource = dataSource.concat(response.result.data)
            setMusicList (dataSource)
        },error => {

        })
    }

    const clickStatusIcon = (data) => {
        if (parseInt(data.data.status) === 1)
        {
            //取消收藏
            let cancelFavorMusicHttpItem = new CancelFavorMusicHttpItem (data.data.songid)
            startConnection (cancelFavorMusicHttpItem).then (response => {
                showTips ('取消收藏成功',0)
                data.data.status = 0
                if (data.data.songid === props.player.songid)
                {
                    //如果当前改变的是正在播放的歌曲
                    props.statusChanged(0)
                }
                for (let i = 0 ; i < musicList.length; i++)
                {
                    let musicData = musicList[i]
                    if (musicData.songid === data.data.songid)
                    {
                        musicData.status = '0'
                        break
                    }
                }
                let tmpDataSources = JSON.parse(JSON.stringify(musicList))
                setMusicList(tmpDataSources)
            } , error => {

            })
        }
        else
        {
            //收藏
            let favorMusicHttpItem = new FavorMusicHttpItem (data.data)
            startConnection (favorMusicHttpItem).then (response => {
                showTips ('收藏歌曲成功',0)
                data.data.status = 1
                if (data.data.songid === props.player.songid)
                {
                    //如果当前改变的是正在播放的歌曲
                    props.statusChanged(1)
                }
                for (let i = 0 ; i < musicList.length; i++)
                {
                    let musicData = musicList[i]
                    if (musicData.songid === data.data.songid)
                    {
                        musicData.status = '1'
                        break
                    }
                }
                let tmpDataSources = JSON.parse(JSON.stringify(musicList))
                setMusicList(tmpDataSources)
            } , error => {

            })
        }
    }

    const clickLoadMoreData = () => {
        let dataCount = musicList.length
        setStartIndex (dataCount)
    }

    const clickView = (data) => {
        if (props.player.songid === data.data.songid)
        {
            if (props.player.isPlaying)
            {
                props.pause ()
            }
            else
            {
                props.play ()
            }
            return
        }

        props.changedSongid(data.data.songid)
    }

    const musicListView = () => {
        let jsxArray = []
        for (let i = 0 ; i < musicList.length ; i++)
        {
            let musicData = musicList[i]
            jsxArray.push (
                <SingerMusicView 
                    height = '60px'
                    key = {musicData.songid}
                    pic = {musicData.pic}
                    title = {musicData.title}
                    duration = {showTimeFormatter(musicData.duration)}
                    statusIcon = {parseInt(musicData.status) === 1 ? 'icon-w_aixin' : 'icon-xihuan'}
                    data = {musicData}
                    hasBottomLine = {i !== (musicList.length - 1)}
                    clickStatusIcon = {clickStatusIcon} 
                    clickView = {clickView}
                />
            )
        }
        if (hasLoadMore)
        { 
            jsxArray.push (
                <Empty 
                    height = '5px'
                    key = 'topSpace'
                />,
                <div className = 'SingerMusicList_LoadMoreButton' key='loadMore' onClick = {clickLoadMoreData}>{'加载更多，已加载('+musicList.length+'首歌)'}</div>,
                <Empty 
                    height = '5px'
                    key = 'bottomSpace'
                />
            )
        }
        return jsxArray
    }

    return(
        <div className = 'SingerMusicList_BgView'>
            {musicListView()}
        </div>
    )
}

const mapStateToProps = (state) => {
    return state
}

const mapDispatchToProps = (dispatch) => {
    return {
        //暂停
        pause : () => {
            dispatch ({type : kIsPause})
        },

        //播放
        play : () => {
            dispatch ({type : kIsPlaying})
        },
 
        //切换歌曲id
        changedSongid : (songid) => {
            dispatch ({type : kSongidChanged, songid : songid})
        },

        //歌曲收藏状态改变
        statusChanged : (status) => {
            dispatch ({type : kStatusHasChanged, status: status})
        },
    }
}

export default connect( mapStateToProps ,mapDispatchToProps )(SingerMusicList)