import { Image } from 'antd'
import '../css/MvView.css'
import Empty from './Empty'

const MvView = (props) => {

    const clickView = () => {
        if (props.clickView)
        {
            props.clickView (props)
        }
    }
  
    return(
        <div className = 'MvView_BgView' key = 'MvView_BgView' onClick = {clickView}>
            <div className = 'MvView_CoverView' key = 'MvView_CoverView'>
                <Image className = "MvView_Cover" src = {props.mvPic} preview = {false} key = 'mvPic'/>
            </div>
            <Empty 
                key = 'Empty1'
                height = '5px'
            />
            <div className = 'MvView_Title' key = 'mvTitle'>{props.mvTitle}</div>
            <div className = 'MvView_SingerName' key = 'mvSingerName'>{props.mvSingerName}</div>
            <Empty 
                key = 'Empty2'
                height = '5px'
            />
        </div>
    )
}

export default MvView