import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BaseRouter } from './router/BaseRouter'
import { Provider } from 'react-redux'
import { applyMiddleware, createStore } from 'redux';
import playReducer from './redux/reducer/playReducer';
import thunk from 'redux-thunk';


const store = createStore (playReducer, applyMiddleware(thunk))

ReactDOM.render(
  <Provider store = {store}>
    <BaseRouter />
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
