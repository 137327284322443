import '../css/LeftIconRightTitle.css'
 
import { createFromIconfontCN } from '@ant-design/icons';
import { kIconFontUrl } from '../global/data';
import { Link } from 'react-router-dom';

const LeftIconRightTitle = (props) => {
 
    const IconFont = createFromIconfontCN({
        scriptUrl: [
          kIconFontUrl
        ],
    });
  
    const clickContentView = () => {
        if (props.clickView)
        {
            props.clickView (props)
        }
    }

    return(
        <div className = 'LeftIconRightTitle_ContentView' onClick = {clickContentView}> 
            <Link to = {props.data.optionType}>
                <div className = {props.data.isChoosed ? 'LeftIconRightTitle_ContentSelect': 'LeftIconRightTitle_Content'}>
                    <IconFont type={props.iconName} className = 'LeftIconRightTitle_Icon'/>
                    <div className = 'LeftIconRightTitle_OptionView'>
                        <h1 className = {props.data.isChoosed ? 'LeftIconRightTitle_OptionSelect' : 'LeftIconRightTitle_Option'}>{props.optionName}</h1>
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default LeftIconRightTitle