import { Image } from 'antd'
import '../css/LeftAvatarRightTitle.css'
import { currentAvatar, currentUserName } from '../data/ManagerUserProfileEntity'

const LeftAvatarRightTitle = (props) => {
 
    return(
        <div className = 'LeftAvatarRightTitle_ContentView'> 
            <div className = 'LeftAvatarRightTitle_Content'>
                <Image
                    className = 'LeftAvatarRightTitle_Avatar'
                    src = {currentAvatar()}
                />
                <div className = 'LeftAvatarRightTitle_NameView'>
                    <h1 className = 'LeftAvatarRightTitle_Name'>{currentUserName()}</h1>
                </div>
            </div>
        </div>
    )
}

export default LeftAvatarRightTitle