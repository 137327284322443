import { message } from 'antd';

import UploadFileTokenHttpItem from '../api/httpItem/UploadFileTokenHttpItem';

import { startConnection } from '../api/httpEngine/HttpEngine'
import MainOptionEntity from '../data/MainOptionEntity';

export const isNull = function(value){
    return value == null
}

export const showTips = (msg ,type) => {
    switch (type)
    {
        case 0:
            message.success(msg)
            break
        case 1:
            message.warning(msg)
            break
        case 2:
            message.error(msg)
            break
        default:
            break;
    }
} 

export const currentUploadFileToken = () => {
    let result = new Promise ((resolve, reject) => {
        let uploadFileToken = new UploadFileTokenHttpItem()
        startConnection(uploadFileToken).then( (response) => {
            resolve(response.data)
        }, error => {
            reject(error)
        })
    })
    return result
}
 
export const choosedOptionViewModel = (title, type, dataModel) => {
    let viewModel = new MainOptionEntity()
    viewModel.optionTitle = title
    viewModel.optionType = type
    viewModel.dataModel = dataModel
    return viewModel
}

export const optionViewModel = (optionIcon , optionTitle, optionType , subOption, isChoosed) => {
    let option = new MainOptionEntity()
    option.optionIcon = optionIcon
    option.optionTitle = optionTitle
    option.optionType = optionType
    option.optionSubOption = subOption
    option.isChoosed = isChoosed
    return option
}

export const showTimeFormatter = (second) => {
    let secondStr = ''
    if (second < 60) 
    {
        secondStr = '00:' + addZero(second)
    }
    else if(second >= 60 && second < 3600)
    { 
        secondStr = addZero(second / 60) + ':' + addZero(second % 60)
    }
    return secondStr
}

const addZero = (number) => {
    if (parseInt(number) < 10)
    {
        number = '0' + parseInt(number)
    } 
    return number
}