import { useState , useEffect } from 'react'
import { connect } from 'react-redux'
import { startConnection } from '../../api/httpEngine/HttpEngine'
import CancelFavorMusicHttpItem from '../../api/httpItem/CancelFavorMusicHttpItem'
import FavorMusicHttpItem from '../../api/httpItem/FavorMusicHttpItem'
import MyMusicCollectionListHttpItem from '../../api/httpItem/MyMusicCollectionListHttpItem'
import '../../css/MyCollectMusicList.css'
import { kIsPause, kIsPlaying, kMusicDataSourceChanged, kSongidChanged, kStatusHasChanged } from '../../redux/reducer/playReducer'
import { isNull, optionViewModel, showTimeFormatter, showTips } from '../../utils/utils'
import Empty from '../../view/Empty'
import FilterView from '../../view/FilterView'
import ListViewHeadView from '../../view/ListViewHeadView'
import MoreHorTagsView from '../../view/MoreHorTagsView'
import MusicItem from '../../view/MusicItem'
import MvUrlHttpItem from '../../api/httpItem/MvUrlHttpItem'
import OneCenterTitle from '../../view/OneCenterTitle'
import SearchBar from '../../view/SearchBar'
import VideoPlayer from '../common/VideoPlayer'
import SingerDetail from '../music/SingerDetail'
 
const MyCollectMusicList = (props) => {

    const [originMusicList, setOriginMusicList] = useState([])
    const [musicList, setMusicList] = useState([])
    const [originFilterListArray, setOriginFilterListArray] = useState([])
    const [filterArray, setFilterArray] = useState([])
    const [type, setType] = useState(1) //1、歌曲 2、歌手
    const [searchContent, setSearchContent] = useState('')
    const [options, setOptions] = useState([])
    const [videoPlayerUrl, setVideoPlayerUrl] = useState('')
    const [isShowVideoPlayView, setIsShowVideoPlayView] = useState(false)
    const [isShowSingerDetail, setIsShowSingerDetail] = useState(false)
    const [singerId, setSingerId] = useState('')

    useEffect(() => {
    
        setType (1)

        let myMusicCollectionListHttpItem = new MyMusicCollectionListHttpItem ()
        startConnection (myMusicCollectionListHttpItem).then (response => {
            setMusicList (response.result.data)
            setOriginMusicList (response.result.data)
            let filterArray = []
            for (let i = 0 ; i < response.result.data.length ; i++)
            {
                let data = response.result.data[i]
                let author = data.author
                let hasContainer = false
                let songs = []

                //如果已经包含
                for (let k = 0 ; k < filterArray.length; k++)
                {
                    let tmp = filterArray[k]
                    if (author === tmp.author)
                    {
                        songs = tmp.songs
                        hasContainer = true
                        break
                    }
                }

                if (hasContainer)
                {
                    //如果已经包含
                    songs.push (data)
                }
                else
                {
                    //如果没有包含，创建一个新的
                    songs.push (data)
                    let tmp = {
                        author : author,
                        songs : songs,
                        pic : data.pic,
                    }
                    filterArray.push (tmp)
                }
            }
            setOriginFilterListArray (filterArray)
            setFilterArray (filterArray)
        }, error => {

        })
    },[])

    useEffect(() => {
        let songTitle = musicList.length > 0 ? "歌曲("+musicList.length+")" : '歌曲'
        let singerTitle = filterArray.length > 0 ? "歌手("+filterArray.length+")" : '歌手' 

        if (options.length > 1)
        {
            if (options.length > 0)
            {
                let optionViewModel = options[0]
                optionViewModel.optionTitle = songTitle
            }
    
            if (options.length > 1)
            {
                let optionViewModel = options[1]
                optionViewModel.optionTitle = singerTitle
            }
            let tmpOptions = JSON.parse(JSON.stringify(options))
            setOptions (tmpOptions)
        }
        else
        {
            let songTitle = musicList.length > 0 ? "歌曲("+musicList.length+")" : '歌曲'
            let singerTitle = filterArray.length > 0 ? "歌手("+filterArray.length+")" : '歌手'
            let options = []
            options.push (optionViewModel(null, songTitle, 1, 0 ,true))
            options.push (optionViewModel(null, singerTitle, 2, 0, false))
            setOptions (options)
        }
    }, [filterArray, musicList])

    useEffect ( () => {
        let choosedData = null
        let index = NaN
        for (let i = 0 ; i < musicList.length ; i++)
        {
            let musicData = musicList[i]
            if (musicData.songid === props.player.songid)
            {
                choosedData = musicData
                index = i
                break
            }
        }
        if (!isNaN(index))
        {
            choosedData.status = props.player.status
        }
    },[props.player.status])

    //搜索文本
    const textDidChanged = (key , value) => {
        setSearchContent (value)
        if (type === 1)
        {
            //歌曲
            if (value.length > 0)
            {
                let tmpMusicDataArray = []
                for (let i = 0 ; i < originMusicList.length; i++)
                {
                    let musicData = originMusicList[i]
                    if (musicData.title.indexOf(value) > -1 || musicData.author.indexOf(value) > -1)
                    {
                        tmpMusicDataArray.push (musicData)
                    }
                }
                setMusicList (tmpMusicDataArray)
            }
            else
            {
                setMusicList (originMusicList)
            }
        }
        else if (type === 2)
        {
            //歌手
            if (value.length > 0)
            {
                let tmpFilterArray = []
                for (let i = 0 ; i < originFilterListArray.length; i++)
                {
                    let musicData = originFilterListArray[i]
                    if (musicData.author.indexOf(value) > -1)
                    {
                        tmpFilterArray.push (musicData)
                    }
                }
                setFilterArray (tmpFilterArray)
            }
            else
            {
                setFilterArray (originFilterListArray)
            }
        }
    }

    //点击播放歌曲
    const clickView = (data) => {
        if (props.player.songid === data.data.songid)
        {
            if (props.player.isPlaying)
            {
                props.pause ()
            }
            else
            {
                props.play ()
            }
            return
        }

        props.changedPlayListDataSources(musicList)
        props.changedSongid(data.data.songid)
    }

    //播放MV
    const clickMVIcon = (data) =>{
        let mvUrlHttpItem = new MvUrlHttpItem(data.data.songid)
        startConnection (mvUrlHttpItem).then (response => {
            setVideoPlayerUrl (response.result.data)
            setIsShowVideoPlayView (true)
            props.pause ()
        },error => {

        })
    }

    //只听他的
    const clickRightView = (data) => {
        let dataModel = data.data
        if (!isNull(dataModel))
        {
            props.changedPlayListDataSources(dataModel.songs)
            if (dataModel.songs.length > 0)
            {
                let firstSong = dataModel.songs[0]
                //默认播放第一个
                props.changedSongid(firstSong.songid)
            }
        }
    }

    //切换选项
    const clickOptionView = (data) => {
        setType (data.data.optionType)
        setSearchContent ('')
        setMusicList (originMusicList)
        setFilterArray (originFilterListArray)
        let copyOptions = options
        for (let i = 0 ; i < copyOptions.length ;i++)
        {
            let option = copyOptions[i]
            option.isChoosed = false
            if (parseInt(option.optionType) === parseInt(data.data.optionType))
            {
                option.isChoosed = true
            }
        }
        setOptions (copyOptions)
    }

    //点（取消）收藏
    const clickFavorIcon = (data) => {
        if (parseInt(data.data.status) === 1)
        {
            //取消收藏
            let cancelFavorMusicHttpItem = new CancelFavorMusicHttpItem (data.data.songid)
            startConnection (cancelFavorMusicHttpItem).then (response => {
                showTips ('取消收藏成功',0)
                data.data.status = 0
                if (data.data.songid === props.player.songid)
                {
                    //如果当前改变的是正在播放的歌曲
                    props.statusChanged(0)
                }
            } , error => {

            })
        }
        else
        {
            //收藏
            let favorMusicHttpItem = new FavorMusicHttpItem (data.data)
            startConnection (favorMusicHttpItem).then (response => {
                showTips ('收藏歌曲成功',0)
                data.data.status = 1
                if (data.data.songid === props.player.songid)
                {
                    //如果当前改变的是正在播放的歌曲
                    props.statusChanged(1)
                }
            } , error => {

            })
        }
    }

    //关闭MV
    const clickVideoPlayer = () => {
        setVideoPlayerUrl ('')
        setIsShowVideoPlayView (false)
        if (!isNull(props.player.songid) && props.player.songid.length > 0)
        {
            props.play()
        }
    }

    //关闭歌手详情
    const clickCloseDetailView = () => {
        setIsShowSingerDetail (false)
    }

    //列表数据
    const listView = () => {
        let jsxArray = []
        if (type === 1)
        {
            //歌曲
            if (musicList.length > 0)
            {
                jsxArray.push (
                    <Empty height = '10px' key = 'MusicList_ContentViewEmptyTopTitleEmpty'/>,
                    <ListViewHeadView
                        firstTitle = '歌名'
                        secondTitle = '歌手'
                        thirdTitle = '时长'
                        fourthTitle = '封面'
                        fifthTitle = 'MV'
                        sixthTitle = '#'
                        key = 'ListViewHeadView'
                    />
                )
                for (let i = 0 ; i < musicList.length ; i++)
                {
                    let musicData = musicList[i]
                    let durationStr = showTimeFormatter (musicData.duration)
                    jsxArray.push (
                        <MusicItem
                            firstTitle = {musicData.title}
                            secondTitle = {musicData.author}
                            thirdTitle = {durationStr}
                            coverImageUrl = {musicData.pic}
                            icon = {parseInt(musicData.status) === 1 ? 'icon-w_aixin' : 'icon-xihuan'}
                            mvIcon = 'icon-yingshi'
                            key = {musicData.songid + i}
                            data = {musicData}
                            clickView = {clickView}
                            clickFavorIcon = {clickFavorIcon}
                            clickMVIcon = {clickMVIcon}
                            isChoosed = {musicData.songid === props.player.songid}
                            canClickSinger = {false}
                        />
                    )
                }
            }
        }
        else if (type === 2)
        {
            //歌手
            if (filterArray.length > 0)
            {
                jsxArray.push (
                    <Empty
                        height = '10px'
                        key = {'FilterViewEmpty'}
                    />, 
                )
                for (let i = 0 ; i < filterArray.length ; i++)
                {
                    let data = filterArray[i]
                    jsxArray.push (
                        <FilterView
                            avatar = {data.pic}
                            topTitle = {data.author}
                            bottomTitle = {"共收藏"+data.songs.length+"首歌"}
                            key = {'FilterView' + i}
                            data = {data}
                            clickRightView = {clickRightView}
                        />, 
                    )
                }
            }
        }
        return jsxArray
    }

    return(
        <div className='MyCollectMusicList_BgView'>
            <div className='MyCollectionMusicList_ContentView'>
                <Empty height = '20px' key = 'MyCollectionMusicList_ContentViewEmpty'/>
                <SearchBar
                    isBorderRadius = {true}
                    modifyKey = 'searchContent'
                    placeHolder = '搜索音乐名称/歌手名称...'
                    text = {searchContent}
                    textDidChanged = {textDidChanged}
                    key = 'searchContent'
                    canEdit = {true}
                />
                <Empty height = '30px' key = 'searchContentEmpty'/>
                <div style = {{marginLeft : '10px'}}>
                    <OneCenterTitle
                        height = '40px'
                        font = '30px'
                        textColor = 'rgb(51, 51, 51)'
                        title = '我喜欢的音乐'
                        textAlign = 'left'
                        key = 'OneCenterTitle'
                    />
                </div>
                <Empty height = '15px' key = 'OneCenterTitleEmpty'/>
                <MoreHorTagsView
                    options = {options}
                    key = 'MoreHorTagsView'
                    clickView = {clickOptionView}
                />
                <Empty height = '15px' key = 'MoreHorTagsViewEmpty'/>
                <div className = 'MyCollectionMusicList_ListView' key = 'MyCollectionMusicList_ListView'>
                    {listView()}
                </div>
                <Empty height = '5px' key = 'MyCollectionMusicList_ListViewEmpty'/>
                <Empty height = '100px' key = 'MyCollectionMusicList_PlayerViewEmpty'/>
            </div>
            <VideoPlayer
                closeIcon = 'icon-round_close_light'
                VideoPlayer = {videoPlayerUrl}
                isShow = {isShowVideoPlayView}
                clickCloseView = {clickVideoPlayer}
            />
            <SingerDetail
                isShow = {isShowSingerDetail}
                singerId = {singerId}
                clickCloseView = {clickCloseDetailView}
            /> 
        </div>
    )
}

const mapStateToProps = (state) => {
    return state
}

const mapDispatchToProps = (dispatch) => {
    return {
        //暂停
        pause : () => {
            dispatch ({type : kIsPause})
        },

        //播放
        play : () => {
            dispatch ({type : kIsPlaying})
        },

        //切换歌曲列表
        changedPlayListDataSources : (list) => {
            dispatch ({type : kMusicDataSourceChanged, musicList : list})
        },

        //切换歌曲id
        changedSongid : (songid) => {
            dispatch ({type : kSongidChanged, songid : songid})
        },

        //歌曲收藏状态改变
        statusChanged : (status) => {
            dispatch ({type : kStatusHasChanged, status: status})
        },
    }
}

export default connect( mapStateToProps ,mapDispatchToProps )(MyCollectMusicList)