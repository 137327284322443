import '../css/LeftIconRightTitleBorder.css'
 
import { createFromIconfontCN } from '@ant-design/icons';
import { kIconFontUrl } from '../global/data';

const LeftIconRightTitleBorder = (props) => {
 
    const IconFont = createFromIconfontCN({
        scriptUrl: [
          kIconFontUrl
        ],
    });
  
    const clickContentView = () => {
        if (props.clickView)
        {
            props.clickView (props)
        }
    }

    return(
        <div className = 'LeftIconRightTitleBorder_ContentView' onClick = {clickContentView}> 
            <div className = 'LeftIconRightTitleBorder_Content'>
                <IconFont type = {props.iconName} className = 'LeftIconRightTitleBorder_Icon'/>
                <div className = 'LeftIconRightTitleBorder_OptionView'>
                    <h1 className = 'LeftIconRightTitleBorder_Option'>{props.optionName}</h1>
                </div>
            </div>
        </div>
    )
}

export default LeftIconRightTitleBorder