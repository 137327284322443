export default class PlayerEntity {
    songid
    currentTime
    currentTimeStr
    title
    author
    duration
    durationStr
    isPlaying
    url
    pic
    percent
    shouldSeekTime//是否是跳动
    volume
    playListDataSource //播放列表
    randomPlayListCountDataSource //随机播放数列表
    mode //播放模式   0顺序播放 1单曲循环 2随机播放
    modeIcon //播放模式Icon
    modeDesc //播放模式描述
    status 
    needReplayMusic //是否重复播放 
    statusChangedSongId //收藏状态有改变的歌曲id

    constructor (){
        this.songid = ''
        this.pic = 'https://cdn.ilovehjl.vip/200.png'
        this.title = '暂无播放歌曲'
        this.author = '暂无歌手'
        this.currentTime = 0
        this.currentTimeStr = '00:00'
        this.duration = 0
        this.durationStr = '00:00'
        this.isPlaying = false
        this.url = ''
        this.percent = 0.0
        this.shouldSeekTime = false
        this.volume = 1
        this.playListDataSource = []
        this.mode = 0 
        this.modeIcon = 'icon-shunxu'
        this.modeDesc = '顺序播放'
        this.status = 0
        this.needReplayMusic = false 
        this.statusChangedSongId = ''
    }
}