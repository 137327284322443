import BaseHttpItem from "./BaseHttpItem"

export default class LoginHttpItem extends BaseHttpItem{
    constructor (userName , password){ 
        super()
        this.userName = userName
        this.password = password
        this.descriptionItem = '登录'
        this.httpRequestUrl = 'user/login'
        this.httpRequestMethod = 'post' 
        this.httpRequestPostParams = {
            "username" : this.userName,
            "password" : this.password
        }
    }
}

