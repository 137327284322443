import '../css/OneIcon.css'
import { createFromIconfontCN } from '@ant-design/icons';
import { kIconFontUrl } from '../global/data';

const OneIcon = (props) => {

    let innerStyle = {height : props.height, width : props.width , backgroundColor: props.backgroundColor}

    let iconStyle = {fontSize : props.fontSize, color : props.textColor, lineHeight : props.fontSize}

    const IconFont = createFromIconfontCN({
        scriptUrl: [
          kIconFontUrl
        ],
    });

    const clickView = (event) => {
        event.stopPropagation()
        if (props.clickView)
        {
            props.clickView (props)
        }
    }

    return(
        <div style={innerStyle} className='OneIcon_ContentView' onClick={clickView}>
            <IconFont type={props.iconName} style={iconStyle}/>
        </div>
    )
}

export default OneIcon