import '../../css/AlbumList.css'

import AlbumListHttpItem from '../../api/httpItem/AlbumListHttpItem'
import { startConnection } from '../../api/httpEngine/HttpEngine'
import AlbumView from '../../view/AlbumView'
import React, { useState , useEffect } from 'react';
import { isNull } from '../../utils/utils'
import Empty from '../../view/Empty'

const AlbumList = (props) => {

    const [dataSource, setDataSource] = useState([])
    const [startIndex, setStartIndex] = useState(0)
    const [hasLoadMore, setHasLoadMore] = useState(false)

    useEffect(() => {
        if (!isNull(props.singerId) && props.singerId.length > 0)
        {
            reloadData()  
        } 
    }, [props.singerId])
    
    useEffect(() => {
        reloadData()  
    }, [startIndex])

    const reloadData = () => {
        let albumListHttpItem = new AlbumListHttpItem(props.singerId, startIndex)
        startConnection (albumListHttpItem).then (response => {
            setHasLoadMore (response.result.data.length === 10)
            let tmpDataSource = dataSource
            tmpDataSource = tmpDataSource.concat(response.result.data)
            setDataSource (tmpDataSource)
        },error => {

        })
    }

    const clickLoadMoreData = () => {
        let dataCount = dataSource.length
        setStartIndex (dataCount)
    }

    //点击专辑view
    const clickAlbumView = (data) => {
        if (props.clickAlbumView)
        {
            props.clickAlbumView (data)
        }
    }

    const albumList = () => {
        let jsxArray = []
        for (let i = 0 ; i < dataSource.length ; i++)
        {
            let albumData = dataSource[i]
            jsxArray.push (
                <div className = 'AlbumList_AlbumView' key = {'AlbumList_AlbumView' + i}>
                    <AlbumView 
                        data = {albumData}
                        key = {albumData.albumId}
                        imageUrl = {albumData.albumPic}
                        albumName = {albumData.albumName}
                        albuReleaseTime = {albumData.albumPublicTime}
                        clickView = {clickAlbumView}
                    /> 
                </div>
            ) 
        }

        if (hasLoadMore)
        { 
            jsxArray.push (
                <Empty
                    height = '5px'
                    key = 'topSpace'
                />,
                <div className = 'AlbumList_LoadMoreButton' key='loadMore' onClick = {clickLoadMoreData}>{'加载更多，已加载('+dataSource.length+'专辑)'}</div>,
                <Empty 
                    height = '5px'
                    key = 'bottomSpace'
                />
            )
        }

        return jsxArray
    }

    return(
        <div className = 'AlbumList_ContentView' key = 'AlbumList_ContentView'>
            {albumList()}
        </div>
    )
}

export default AlbumList