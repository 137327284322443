import { kHttpDomainUrl , kHttpTimeOut } from "../../global/data"

export default class BaseHttpItem{
    httpReqeustDomainUrl = kHttpDomainUrl
    httpRequestUrl = ''
    httpRequestResponseData = {}
    httpRequestErrorMessage = ''
    httpRequestStatusCode = 0
    httpRequestResponseDataJson 
    httpRequestMethod = 'get'
    httpRequestTimeoutCount = kHttpTimeOut
    httpRequestHeaderParams = {}
    httpRequestPostParams = {}
    descriptionItem = ''

    displayItemInformation (){

    }
}