import '../../css/SingerDetail.css'
import { isNull } from '../../utils/utils'
import SingerHeadView from '../../view/SingerHeadView'
import SingerDetailHttpItem from '../../api/httpItem/SingerDetailHttpItem'
import { startConnection } from '../../api/httpEngine/HttpEngine'
import React, { useState , useEffect } from 'react';
import Empty from '../../view/Empty'
import ThreeOptions from '../../view/ThreeOptions'
import OneLine from '../../view/OneLine' 
import OneIcon from '../../view/OneIcon'
import SingerMusicList from './SingerMusicList'
import AlbumList from './AlbumList'
import AlbumMusicList from './AlbumMusicList'
import MvList from './MvList'

const SingerDetail = (props) => {

    const [singerData, setSingerData] = useState({})
    const [selectedOption, setSelectedOption] = useState(1)
    const [isShowAlbumList, setIsShowAlbumList] = useState(false)
    const [albumId, setAlbumId] = useState('')
    const [albumName, setAlbumName] = useState('')

    useEffect(() => {
      if (!isNull(props.singerId) && props.singerId.length > 0)
      {
        let singerDetailHttpItem = new SingerDetailHttpItem(props.singerId)
        startConnection (singerDetailHttpItem).then (response => {
          setSingerData (response.result.data)
        },error => {

        })
      }
    }, [props.singerId])

    const clickOption = (index) => {
      setSelectedOption (index)
    }

    //点击专辑view
    const clickAlbumView = (data) => {
      if (!isNull(data.data.albumId) && data.data.albumId.length > 0)
      {
        setAlbumName (data.data.albumName)
        setAlbumId(data.data.albumId)
        setIsShowAlbumList (true)
      }
    }

    const optionList = () => {
      let jsxArray = []
      switch (selectedOption)
      {
        case 1: 
          jsxArray.push (
            <SingerMusicList
              key = 'SingerMusicList'
              singerId = {props.singerId}
            />
          )
          break
        case 2:
          jsxArray.push (
            <AlbumList
              key = 'AlbumList'
              singerId = {props.singerId}
              clickAlbumView = {clickAlbumView}
            />
          )
          break
        case 3:
          jsxArray.push (
            <MvList 
              key = 'MvList'
              singerId = {props.singerId}
            />
          )
          break
        default:
          break
      }
      return jsxArray
    }

    //点击关闭
    const clickCloseView = () => {
        if (props.clickCloseView)
        {
          props.clickCloseView()
        }
    }
    
    //点击关闭专辑列表页面
    const clickCloseAlbumMusicListView = () => {
      setIsShowAlbumList (false)
    }

    return (  
      <div className = 'SingerDetail_BgView' style = {{display : props.isShow === false ? 'none': ''}} key = 'SingerDetail_BgView'>
        <div className = "SingerDetail_CloseIcon" onClick = {clickCloseView} key = 'SingerDetail_CloseIcon'>
            <OneIcon
              width = '50px'
              height = '50px'
              fontSize = '50px'
              textColor = 'rgb(255, 255, 255)'
              iconName = 'icon-round_close_light'
              clickView = {clickCloseView}
              key = 'OneIcon1'
            />
        </div>
        <div className = "SingerDetail_ContentView" key = 'SingerDetail_ContentView'>
            <Empty 
              key = 'Empty1'
              height = '10px'
            />
            <SingerHeadView
              key = 'SingerHeadView'
              height = '200px'
              pic = {singerData.singer_pic}
              description = {singerData.singer_descption}
            />
            <Empty 
              key = 'Empty2'
              height = '10px'
            />
            <div style = {{marginLeft : '40px', width : 'calc(100% - 40px - 40px)', marginRight : '40px'}} key = 'OneLine1'>
              <OneLine key = 'subOnline1'/>
            </div>
            <ThreeOptions
              firstTopTitle = {singerData.songCount}
              firstBottomTitle = '歌曲总数'
              secondTopTitle = {singerData.albumCount}
              secondBottomTitle = '专辑总数'
              thirdTopTitle = {singerData.mvCount}
              thirdBottomTitle = 'MV总数'
              selectedIndex = {selectedOption}
              clickOption = {clickOption}
              key = 'ThreeOptions'
            />
            <div style = {{marginLeft : '40px', width : 'calc(100% - 40px - 40px)', marginRight : '40px'}} key = 'OneLine2'>
              <OneLine key = 'subOnline2'/>
            </div>
            <Empty 
              height = '10px'
              key = 'Empty3'
            />
            <div className = 'SingerDetail_OptionList' key = 'SingerDetail_OptionList'>
                {optionList()}
            </div>
        </div>  
        <AlbumMusicList 
            key = 'AlbumMusicList'
            albumId = {albumId}
            albumName = {albumName}
            isShow = {isShowAlbumList}
            clickCloseView = {clickCloseAlbumMusicListView}
        />
      </div>
    )
}

export default SingerDetail