import './App.css';
import Login from './controller/common/Login'
import React from "react";

class App extends React.Component {
  render() {
    return (
      <Login />
    )
  }
}

export default App;
