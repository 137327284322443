import { Image } from 'antd'
import '../css/SingerMusicView.css'
import OneIcon from './OneIcon'
import WidthEmpty from './WidthEmpty'

const SingerMusicView = (props) => {

    const clickStatusIcon = () => {
        if (props.clickStatusIcon)
        {
            props.clickStatusIcon (props)
        }
    }

    const clickView = () => {
        if (props.clickView)
        {
            props.clickView (props)
        }
    }

    return (  
      <div className = 'SingerMusicView_ContentView' style = {{height : props.height}} key = 'SingerMusicView_ContentView' onClick={clickView}>
        <div className = 'SingerMusicView_OptionView' key =  'SingerMusicView_OptionView'>
            <Image
                className = 'SingerMusicView_Avatar'
                src = {props.pic}
                key = 'SingerMusicView_Avatar'
            />
            <div className = 'SingerMusicView_TitleView' key = 'title'>{props.title}</div>
            <div className = 'SingerMusicView_TitleView' key = 'duration'>{props.duration}</div>
            <WidthEmpty 
                width = '30px'
                height = '100%'
                key = 'WidthEmpty1'
            />
            <OneIcon
                width = '30px'
                height = '30px'
                fontSize = '30px'
                textColor = 'rgb(51, 51, 51)'
                iconName = {props.statusIcon}
                clickView = {clickStatusIcon}
                key = 'OneIcon'
            />
            <WidthEmpty 
                key = 'WidthEmpty2'
                width = '30px'
                height = '100%'
            />
        </div>
        <div style = {{marginLeft : '30px', marginRight : '10px', width : 'calc(100% - 10px - 10px)', backgroundColor : 'rgb(235, 235, 235)', height : '1px', display : props.hasBottomLine ? '': 'none'}} key = 'line'/>
      </div>
    )
}

export default SingerMusicView