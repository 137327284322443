import '../css/LeftTitleRightPageIndex.css'
import OneIcon from './OneIcon';
 
const LeftTitleRightPageIndex = (props) => {

    let innerStyle = {height : props.height, width : '100%' , backgroundColor: props.backgroundColor}
 
    const clickPreviewIcon = () => {
        if (props.clickPreviewIcon)
        {
            props.clickPreviewIcon ()
        }
    }

    const clickNextIcon = () => {
        if (props.clickNextIcon)
        {
            props.clickNextIcon ()
        }
    }

    return(
        <div className = 'LeftTitleRightPageIndex_ContentView' style = {innerStyle} key = 'LeftTitleRightPageIndex_ContentView'>
            <div className = 'LeftTitleRightPageIndex_OptionView' key = 'LeftTitleRightPageIndex_OptionView'>
                <h1 className = 'LeftTitleRightPageIndex_Left' key = 'LeftTitleRightPageIndex_Left'>{props.leftTitle}</h1>
                <div className = 'LeftTitleRightPageIndex_RightOptionView'>
                    <OneIcon
                        width = '30px'
                        height = '30px'
                        fontSize = '18px'
                        textColor = 'rgb(255, 255, 255)'
                        iconName = 'icon-jiantou_shangyiye'
                        clickView = {clickPreviewIcon}
                        key = 'previewIcon'
                    />
                    <div className = 'LeftTitleRightPageIndex_pageTitleView' key = 'LeftTitleRightPageIndex_pageTitleView'>
                        <h1 className='LeftTitleRightPageIndex_pageTitle' key = 'LeftTitleRightPageIndex_pageTitle'>{props.currentPage}</h1>
                    </div>
                    <OneIcon
                        key = 'nextIcon'
                        width = '30px'
                        height = '30px'
                        fontSize = '18px'
                        textColor = 'rgb(255, 255, 255)'
                        iconName = 'icon-jiantou_xiayiye'
                        clickView = {clickNextIcon}
                    />
                </div>
            </div>
        </div>
    )
}

export default LeftTitleRightPageIndex