import BaseHttpItem from "./BaseHttpItem"

export default class PlayUrlHttpItem extends BaseHttpItem{
    constructor (songId){ 
        super()
        this.descriptionItem = '获取音乐播放链接'
        this.httpRequestUrl = 'music/checkPlayUrl'
        this.httpRequestMethod = 'post' 
        this.httpRequestPostParams = {
            songId : songId
        }
    }
}