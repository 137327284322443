import { isNull } from "../utils/utils"

export const isLogin = function ()  { 
    return (!isNull(currentToken()) && currentToken().length > 0)
}

export const resetManagerUserEntity = (entity) => {
    window.sessionStorage.setItem ('username', entity["username"])
    window.sessionStorage.setItem ('token',       entity["token"])
    window.sessionStorage.setItem ('userId', entity["userId"])
    window.sessionStorage.setItem ('userAvatar' , entity["userAvatar"])
    window.sessionStorage.setItem ('nickName' , entity["nickName"])
}

export const currentUserName = () => {
    return window.sessionStorage.getItem ('username')
}

export const currentUserId = () => {
    return window.sessionStorage.getItem ('userId')
}
 
export const currentToken = () => {
    let tmp = window.sessionStorage.getItem ('token')
    return tmp
}

export const currentAvatar = () => {
    let tmp = window.sessionStorage.getItem ('userAvatar')
    return tmp
}

export const currentNickName = () => {
    let tmp = window.sessionStorage.getItem ('nickName')
    return tmp
}
 
export const logout = () => {
    window.sessionStorage.setItem('token','')
    window.sessionStorage.setItem('userId', '')

    setTimeout(() => {
        window.location.href = '/login'
    }, 1500)
}