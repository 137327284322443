import { HashRouter , Route, Switch } from 'react-router-dom'

// 一级路由
import App      from '../App'
import Main     from '../controller/main/Main'
import Error    from '../controller/error/Error'
import Login    from '../controller/common/Login'
import MusicList from '../controller/music/MusicList'
import MyCollectMusicList from '../controller/mine/MyCollectMusicList'

export const BaseRouter = () => {
    return (
        <HashRouter>
            <Switch>
                <Route path = '/main' component = {Main}></Route>
                <Route exact path = '/' component = {App}></Route>
                <Route exact path = '/login' component = {Login}></Route>
                <Route component = {Error}></Route>
            </Switch>
        </HashRouter>
    )
}

export const AreaRouterList = () => {
    return (
        <Switch>
            <Route exact path='/main' component={MusicList}></Route>
            <Route exact path='/main/musicList' component={MusicList}></Route>
            <Route exact path='/main/myCollectionList' component={MyCollectMusicList}></Route>
        </Switch>
    )
} 