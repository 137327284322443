import '../css/WidthEmpty.css'
 
const WidthEmpty = (props) => {
    
    let innerStyle = {height : props.height, width : props.width , backgroundColor: props.backgroundColor}

    return(
        <div style={innerStyle}>
        </div>
    )
}

export default WidthEmpty