import '../css/Loading.css' 
import { Player } from '@lottiefiles/react-lottie-player';

const Loading = (props) => {
    let innerStyle = {visibility: props.showLoading === true ? "visible" : "hidden" }
    return(
        <div style = { innerStyle } className = 'Loading_ContentView'> 
            <Player className = 'Loading_lottie'
                autoplay = {true}
                loop = {true}
                controls = {true}
                src = " https://assets10.lottiefiles.com/packages/lf20_omullrhw.json"
            />
        </div>
    )
}

export default Loading