import { startConnection } from "../api/httpEngine/HttpEngine"
import PlayUrlHttpItem from "../api/httpItem/PlayUrlHttpItem"
import { kCheckMusicUrl, kEndProgressChanged, kEndReplayMusic, kIsPause, kIsPlaying, kNextMusic, kPlayDuration, kPlayInfo, kPlayProgress, kStartProgressChanged } from "../redux/reducer/playReducer"
import { isNull, showTips } from "../utils/utils"
import React, { useRef,useEffect } from 'react';
import { connect } from 'react-redux'
import ReactPlayer from "react-player";
import MusicDetailInfoHttpItem from "../api/httpItem/MusicDetailInfoHttpItem";

const PlayerEngine = (props) => {
    
    const player = useRef(null)

    useEffect(() => {
        if (!isNull(props.player.songid) && props.player.songid.length > 0)
        {
            let musicDetailInfoHttpItem = new MusicDetailInfoHttpItem(props.player.songid)
            startConnection (musicDetailInfoHttpItem).then (aResult => {
                props.checkMusicInfo (aResult.result.data)
                let playUrlHttpItem = new PlayUrlHttpItem (props.player.songid)
                startConnection (playUrlHttpItem).then ( bResult => {
                    props.checkUrlCompleted (bResult.result.data.url)
                }, bError => {

                })
            }, aErro => {

            })
        }
    }, [props.player.songid])

    useEffect(() => {
        if (props.player.shouldSeekTime === true)
        { 
            props.startChangeProgress ()
            player.current.seekTo (parseFloat(props.player.percent))
            props.endChangeProgress ()
        }
    }, [props.player.shouldSeekTime])

    useEffect(() => {
        if (props.player.needReplayMusic === true)
        { 
            player.current.seekTo (0)
            props.endReplayMusic ()
        }
    }, [props.player.needReplayMusic])
  
    return(
        <div>
            <ReactPlayer
                loop = {false}
                url = {props.player.url}  
                onStart = {props.onStart}
                onPlay = {props.onPlay}
                onPause = {props.onPause}
                onProgress = {props.onProgress}
                onDuration = {props.onDuration}
                onError = {props.onError}
                onEnded = {props.onEnded}
                width = {0}
                height = {0}
                playing = {props.player.isPlaying}
                ref = {player}
                volume = {props.player.volume}
            />
        </div>
    )
}

const mapStateToProps = (state) => {
    return state
}

const mapDispatchToProps = (dispatch) => {
    return {

        //链接获取
        checkUrlCompleted : (url) => {
            dispatch ({type : kCheckMusicUrl, url : url})
        },

        //音乐数据
        checkMusicInfo : (data) => {
            dispatch ({type : kPlayInfo , data : data})
        },

        //开始播放
        onStart : () => {
            dispatch({type : kIsPlaying})
        },

        //继续播放
        onPlay : () => {
            dispatch({type : kIsPlaying})
        },

        //暂停
        onPause : () => {
            dispatch ({type : kIsPause})
        },

        //进度
        onProgress : (info) => {
            dispatch ({type : kPlayProgress , progress : info})
        },

        //总共时长
        onDuration : (duration) => {
            dispatch ({type : kPlayDuration, duration : duration})
        },

        //播放失败
        onError : () => {
            showTips ('播放失败,请联系马天', 2)
            dispatch ({type : kIsPause})
        },

        //开始改变进度 
        startChangeProgress : () => {
            dispatch ({type : kStartProgressChanged})
        },

        //结束改变进度
        endChangeProgress : () => {
            dispatch ({type : kEndProgressChanged})
        },

         //音乐播放结束
        onEnded : () => {
            dispatch ({type : kNextMusic})
        },

        //结束重复播放
        endReplayMusic : () => {
            dispatch ({type : kEndReplayMusic}) 
        },
    }
}

export default connect( mapStateToProps ,mapDispatchToProps )(PlayerEngine)